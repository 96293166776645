import { useCallback, useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { Form, Input, Button, Modal, Space, Divider, Typography, Spin } from 'antd'
import { ArrowLeftOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons'

import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
    useDeleteKubearkInstanceMutation,
    useGetKubearkInstancesQuery,
    useUpdateKubearkInstanceMutation,
} from '../api/apiSlice'
import { validationSchemaKubeark } from '../../validations/kubeark'
import { ModalConfirmExit } from './modalConfirmExit'
import { getKubearkInstanceFromInstances, isKubearkInstanceNameTaken } from '../../utilities/kubeark'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { IKInstance } from '../../core-types'

const { Title, Text } = Typography
const { confirm } = Modal

// WIP Don't give navigate as a param like this. use a callback
// Modal delete
const showDeleteConfirmModal = (id: number, deleteInstance: any, navigate: any) => {
    confirm({
        centered: true,
        icon: null,
        content: `Delete this Kubeark instance?`,
        okText: 'Delete Instance',
        okType: 'danger',
        cancelText: 'No',
        async onOk() {
            await deleteInstance(id)
            navigate('/kubeark')
        },
        onCancel() {},
    })
}

// Error modal
const showErrorModal = (status: number | string) => {
    Modal.error({
        content: (
            <span className="text-red-500">
                <span className="font-semibold">Error {status}</span>
                <br />
                Cannot add Kubeark instance!
            </span>
        ),
        okType: 'danger',
        icon: null,
        centered: true,
    })
}

type FormValues = {
    name: string
    kubeark_url: string
    kubeark_access_token: string
}

type ControllerRenderProps = {
    field: any
    fieldState: any
    formState: any
}

// settings for the form control
const formSettings = {
    name: {
        maxLength: 250,
    },
    kubeark_url: {
        maxLength: 1000,
    },
    kubeark_access_token: {
        maxLength: 1000,
    },
}

// custom hook for main queries logic
const useMainQueries = (id: number) => {
    const kinstancesQuery = useGetKubearkInstancesQuery({})

    // get the current offer
    const [currentInstance, setCurrentInstance] = useState<IKInstance | undefined>(undefined)

    // set the current instance, after fetching k instances
    useEffect(() => {
        kinstancesQuery.isSuccess && setCurrentInstance(getKubearkInstanceFromInstances(id, kinstancesQuery.data))
    }, [id, kinstancesQuery.isSuccess, kinstancesQuery.data])

    return {
        kinstancesQuery,
        currentInstance,
    }
}

export const ViewKubearkDetailsEdit = () => {
    // HOOKS

    // Router
    const navigate = useNavigate()

    // instance id
    const { id } = useParams()

    // type guard
    if (!id) throw new Error('Error: Bad instance id!')

    const { kinstancesQuery, currentInstance } = useMainQueries(Number(id))

    // React Hook Form
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: zodResolver(validationSchemaKubeark),
    })

    const [currentKInstanceName, setCurrentKInstanceName] = useState<string | null>(null)
    const [showDuplicatedNameError, setShowDuplicatedNameError] = useState(false)
    const [nameAlreadyExists, setNameAlreadyExists] = useState(false)

    // Confirm Exit Modal
    const [showConfirmQuitModal, setShowConfirmQuitModal] = useState(false)

    // Mutations
    // const [addKubearkInstance, addKubearkInstanceMutation] = useAddNewKubearkInstanceMutation()

    const [updateKubearkInstance, updateKubearkInstanceMutation] = useUpdateKubearkInstanceMutation()
    const [deleteKubearkInstance, deleteKubearkInstanceMutation] = useDeleteKubearkInstanceMutation()

    // k instance successfully added, navigate back to the list
    useEffect(() => {
        updateKubearkInstanceMutation.isSuccess && navigate('/kubeark')
    }, [updateKubearkInstanceMutation.isSuccess, navigate])

    // error adding instance, show error modal and stay on page
    useEffect(() => {
        updateKubearkInstanceMutation.isError &&
            updateKubearkInstanceMutation.error &&
            showErrorModal((updateKubearkInstanceMutation.error as FetchBaseQueryError).status)
    }, [updateKubearkInstanceMutation.isError, updateKubearkInstanceMutation.error])

    // if the k instances are ready, and I have a current k instance name (onBlur event occured after typing the name),
    // check if the name already exists in other instance name, other thean the current instance we are editing.
    useEffect(() => {
        if (kinstancesQuery.isSuccess && currentKInstanceName) {
            const instanceName = getKubearkInstanceFromInstances(Number(id), kinstancesQuery.data)?.name
            if (isKubearkInstanceNameTaken(currentKInstanceName, kinstancesQuery.data, instanceName)) {
                setNameAlreadyExists(true)
            } else {
                setNameAlreadyExists(false)
            }
        }
    }, [kinstancesQuery.isSuccess, kinstancesQuery.data, currentKInstanceName, nameAlreadyExists, id])

    // HANDLERS

    // Confirm Exit Modal

    const onBackArrowClick = useCallback(() => {
        setShowConfirmQuitModal(true)
    }, [])

    const onOkModalExit = useCallback(() => {
        navigate('/kubeark')
    }, [navigate])

    const onCancelModalExit = useCallback(() => {
        setShowConfirmQuitModal(false)
    }, [])

    // submit the form
    const onSubmit = useCallback(
        async (data: any) => {
            // do not update the token if it doesn't change
            if (data?.kubeark_access_token === '***') delete data.kubeark_access_token

            try {
                await updateKubearkInstance({ ...data, id })
            } catch (error) {
                throw new Error(`Cannot add Kubeark Instance`)
            }
        },
        [updateKubearkInstance, id]
    )

    const onDeleteInstanceClicked = useCallback(async () => {
        showDeleteConfirmModal(Number(id), deleteKubearkInstance, navigate)
    }, [id, deleteKubearkInstance, navigate])

    // JSX

    // name
    const renderName = useCallback(({ field: { onChange, onBlur, value } }: ControllerRenderProps): JSX.Element => {
        return (
            <Input
                className="max-w-[280px]"
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(ev)
                    // hide the duplicate name error while editing
                    setShowDuplicatedNameError(false)
                }}
                onBlur={(ev: React.FocusEvent<HTMLInputElement>) => {
                    onBlur()
                    const name = ev.target.value

                    // if the kubeark name field in not empty
                    if (name) {
                        setShowDuplicatedNameError(true)
                        setCurrentKInstanceName(name)
                    }
                }}
                value={value}
                maxLength={formSettings['name'].maxLength}
            />
        )
    }, [])

    // kubeark_url

    const renderKubearkUrl = useCallback(
        ({ field: { onChange, value } }: ControllerRenderProps) => (
            <Input
                className="max-w-[280px]"
                onChange={onChange}
                value={value}
                maxLength={formSettings['kubeark_url'].maxLength}
            />
        ),
        []
    )

    // kubeark_access_token

    const renderKubearkAccessToken = useCallback(
        ({ field: { onChange, value } }: ControllerRenderProps) => (
            <Input
                className="max-w-[280px]"
                onChange={onChange}
                value={value}
                maxLength={formSettings['kubeark_access_token'].maxLength}
            />
        ),
        []
    )

    return (
        <div className="w-full">
            {/* Confirm Exit Modal */}
            <ModalConfirmExit open={showConfirmQuitModal} onOk={onOkModalExit} onCancel={onCancelModalExit} />

            {/* View Header */}

            <header className="grid grid-cols-[max-content_minmax(0,_1fr)_minmax(0,_1fr)] grid-rows-[max-content]">
                {/* arrow */}
                <div className="self-center">
                    <a onClick={onBackArrowClick} className="ml-[-32px] w-[32px]">
                        <ArrowLeftOutlined className="text-[18px]" />
                    </a>
                </div>

                {/* left side */}
                <div className="self-center">
                    <Title level={2} className="!my-0">
                        Edit Instance
                    </Title>
                </div>

                {/* right side */}
                <div className="self-center text-right">
                    <Space size="small">
                        <Space size="large">
                            {/* Delete Offer */}
                            <Button
                                // type="primary"
                                danger
                                onClick={onDeleteInstanceClicked}
                                disabled={
                                    deleteKubearkInstanceMutation.isLoading || updateKubearkInstanceMutation.isLoading
                                }
                            >
                                <DeleteOutlined />
                            </Button>

                            <Divider type="vertical" className="h-10" />

                            {/* Cancel (editing) */}
                            <Link to={`/kubeark/${id}`}>
                                {/* disabled={isLoadingAddNewOffer} */}
                                <Button disabled={updateKubearkInstanceMutation.isLoading}>Cancel</Button>
                            </Link>
                        </Space>

                        {/* Update  */}
                        <Button
                            onClick={handleSubmit(onSubmit)}
                            type="primary"
                            loading={updateKubearkInstanceMutation.isLoading}
                            // disable this button if the K instance name is duplicated (and the error message is shown)
                            // Tip: I've used the double bang operator `!!` to convert a truthy value (a name) to boolean.
                            disabled={
                                (showDuplicatedNameError && nameAlreadyExists) ||
                                (!!currentKInstanceName && kinstancesQuery.isFetching)
                            }
                        >
                            Update
                        </Button>
                    </Space>
                </div>

                <div></div>
                <div>
                    <Text type="secondary">Edit this Kubeark instance.</Text>
                </div>
            </header>

            <Divider />
            {kinstancesQuery.isLoading && <Spin indicator={<LoadingOutlined className="text-sm" spin />} />}

            {kinstancesQuery.isSuccess && (
                <Form className="flex flex-col" disabled={updateKubearkInstanceMutation.isLoading}>
                    {/* columns */}

                    {currentInstance && (
                        <section className="flex flex-col md:flex-row">
                            {/* column 1 */}

                            <div className="w-full mr-4 md:w-1/2">
                                {/* name */}
                                <div className="mt-4">
                                    <div className="text-gray-400 ">
                                        <span className="mr-4">Name</span>
                                    </div>
                                    <div className="my-0">
                                        <Controller
                                            name="name"
                                            defaultValue={currentInstance.name}
                                            control={control}
                                            render={renderName}
                                        />
                                    </div>
                                    <div className="text-xs text-orange-500 mt-1 min-h-[16px]">
                                        {/* validation error */}
                                        {errors.name && errors.name.message}

                                        {/* name alread exists (async checked, not part of validation) */}
                                        {kinstancesQuery.isFetching && currentKInstanceName && (
                                            <span className="text-blue-500"> Checking Kubeark name... </span>
                                        )}
                                        {showDuplicatedNameError && nameAlreadyExists && (
                                            <span className="text-red-500Kubeark">
                                                This Kubeark name already exists!
                                            </span>
                                        )}
                                    </div>
                                </div>

                                {/* Kubeark URL */}
                                <div className="mt-4">
                                    <div className="text-gray-400 ">
                                        <span className="mr-4">Kubeark URL</span>
                                    </div>
                                    <div className="my-0">
                                        <Controller
                                            name="kubeark_url"
                                            defaultValue={currentInstance.kubeark_url}
                                            control={control}
                                            render={renderKubearkUrl}
                                        />
                                    </div>
                                    <div className="text-xs text-orange-500 mt-1 min-h-[16px]">
                                        {errors.kubeark_url && errors.kubeark_url.message}
                                    </div>
                                </div>

                                {/* Kubeark Access Token */}
                                <div className="mt-4">
                                    <div className="text-gray-400 ">
                                        <span className="mr-4">Kubeark Access Token</span>
                                    </div>
                                    <div className="my-0">
                                        <Controller
                                            name="kubeark_access_token"
                                            defaultValue={currentInstance.kubeark_access_token}
                                            control={control}
                                            render={renderKubearkAccessToken}
                                        />
                                    </div>
                                    <div className="text-xs text-orange-500 mt-1 min-h-[16px]">
                                        {errors.kubeark_access_token && errors.kubeark_access_token.message}
                                    </div>
                                </div>
                            </div>

                            {/* column 2 */}

                            <div className="w-full md:w-1/2"></div>
                        </section>
                    )}
                </Form>
            )}
        </div>
    )
}
