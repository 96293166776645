import React from 'react'

import { prettyDate } from '../../../utilities/tools'
import { Avatar, Typography, theme } from 'antd'
import { IOrder } from '../../../core-types'
import { svgIconApi, svgIconDate } from '../../../svgs'
import { OrderStatus } from '../../components/OrderStatus'
import { DeploymentStatus } from '../../components/DeploymentStatus'

const { Text } = Typography

const { useToken } = theme

interface IProps {
    order: IOrder
}

export const OrderDetails: React.FC<IProps> = ({ order }: IProps) => {
    const { token } = useToken()

    // JSX
    return (
        <div
            className="grid grid-rows-[max-content_1fr]  rounded-md shadow-md border-solid border"
            style={{ borderColor: token.colorBgLayout }}
        >
            <header
                className="grid grid-cols-1 md:grid-cols-[max-content_1fr] gap-8 xl:gap-12 xl:grid-cols-[max-content_max-content_max-content_max-content_minmax(max-content,_1fr)] p-4"
                style={{ borderColor: token.colorBgLayout }}
            >
                {/* col */}
                <div>
                    <Text type="secondary" className="block">
                        Offer
                    </Text>
                    <Text className="block font-semibold">{order.offer_id}</Text>
                </div>

                {/* col */}
                <div>
                    <Text className="block" type="secondary">
                        Plan Id
                    </Text>
                    <Text>{order.plan_id}</Text>
                </div>

                {/* col */}
                <div>
                    <Text type="secondary" className="block mb-1">
                        Subscription Status
                    </Text>
                    <OrderStatus status={order?.status} />
                </div>

                {/* col */}
                <div>
                    <Text type="secondary" className="block mb-1">
                        Deployment Status
                    </Text>
                    <DeploymentStatus status={order?.deployment_status} />
                </div>

                {/* col */}
                <div className="flex flex-row items-center xl:justify-end">
                    <div className="order-2 xl:order-1 xl:text-right">
                        <Text className="block font-semibold">{order.client_name}</Text>
                        <Text type="secondary">{order.client_email}</Text>
                    </div>
                    <div className="order-1 mr-4 xl:order-2 xl:ml-4 xl:mr-0 xl:block">
                        <Avatar size="large" style={{ background: token.colorBorder }} />
                    </div>
                </div>
            </header>

            <main
                className="grid grid-cols-1 px-4 py-8 lg:grid-cols-2"
                style={{ backgroundColor: token.colorBgLayout }}
            >
                <div className="grid gap-x-8 grid-cols-1 lg:grid-cols-[max-content_max-content]">
                    <div
                        className="pr-4 border-0 border-solid lg:pr-16 lg:border-r"
                        style={{ borderColor: token.colorBorder }}
                    >
                        {/* icon */}
                        <div className="mb-4">
                            <Text style={{ color: token.colorPrimary }}>
                                <div className="w-5 h-5">{svgIconApi}</div>
                            </Text>
                        </div>
                        <div className="mt-4">
                            <Text className="block" type="secondary">
                                Deployment
                            </Text>
                            <Text className="block font-mono text-xs">{order?.deployment_name}</Text>
                        </div>

                        <div className="mt-4">
                            <Text className="block" type="secondary">
                                Subscription Id
                            </Text>
                            <Text className="block font-mono text-xs">{order?.subscription_id}</Text>
                        </div>

                        <div className="mt-4">
                            <Text className="block" type="secondary">
                                Tenant Id
                            </Text>
                            <Text className="block font-mono text-xs">{order?.tenant_id}</Text>
                        </div>

                        <div className="mt-4">
                            <Text className="block" type="secondary">
                                GUID
                            </Text>
                            <Text className="block font-mono text-xs">{order?.guid}</Text>
                        </div>
                    </div>

                    <div className="pt-8 pr-4 lg:pt-0 lg:pr-16">
                        {/* icon */}
                        <div className="mb-4">
                            <Text style={{ color: token.colorPrimary }}>
                                <div className="w-5 h-5">{svgIconDate}</div>
                            </Text>
                        </div>

                        {/* Updated at */}
                        <div className="mt-4">
                            <Text className="block" type="secondary">
                                Last Update
                            </Text>
                            <Text className="block text-xs">{prettyDate(new Date(order?.updated_at))}</Text>
                        </div>

                        {/* Created at */}
                        <div className="mt-4">
                            <Text className="block" type="secondary">
                                Created At
                            </Text>
                            <Text className="block text-xs">{prettyDate(new Date(order?.created_at))}</Text>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
