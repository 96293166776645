import { Button, Drawer, Space } from 'antd'

type Props = {
    title: string
    isOpen: boolean
    isLoading: boolean
    isDirty: boolean
    // handlers
    onClose: any
    children: React.ReactNode
}

const DrawerOfferOverview = ({ title, isOpen, isLoading, onClose, isDirty, children }: Props) => {
    return (
        <Drawer
            rootClassName="drawer-offer"
            title={title}
            placement="right"
            size={'default'}
            width="720px"
            height={'auto'}
            destroyOnClose={true}
            onClose={onClose}
            open={isOpen}
            extra={
                <Space>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        form="form_offer"
                        type="primary"
                        htmlType="submit"
                        disabled={!isDirty || isLoading}
                        loading={isLoading}
                    >
                        Save
                    </Button>
                </Space>
            }
        >
            {children}
        </Drawer>
    )
}

export default DrawerOfferOverview
