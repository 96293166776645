import { FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import type { RootState } from '../../app/store'
import { logOut, setCredentials } from '../auth/authSlice'

// TODO After the token expires, do I get a cached 401 for a certain endpoint? So if I login again I don't get the data? Should I force invalidate the cache at every login?

// NOTE fetch API - credentials
// This is a Fetch API setting. It will include the cookies and authorization headers in the request to the server.
// By default, fetch does not include them.
// If you want to include them only for same-domain requests, use 'same-origin'. If you want to omit them, use 'omit'.
// NOTE backend - 'authorization'
// The backend should look for both 'authorization' and 'Authorization' header.

const BASE_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

const baseQuery = fetchBaseQuery({
    // baseUrl: process.env.REACT_APP_KTA_API_URL,
    baseUrl: BASE_URL,
    credentials: 'include',
    // mode: 'no-cors',
    prepareHeaders: (headers: Headers, { getState }) => {
        const token = (getState() as RootState).auth.token
        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }

        // So, we are attaching the 'authorization' header with the JWT token to every request
        return headers
    },
})

// FEATURE Auth - Wraper for baseQuery to silently get a refreshed token if the current one is expired.
// Create a wraper for the baseQuery so we can use it when the first call of baseQuery fails because the access token expired.
const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    let result: any = await baseQuery(args, api, extraOptions)

    // you can handle various status codes here

    // Suppose that the backend returns a 403 Forbidden if the access token received is valid but is expired and returns 401 Unauthorized if there is no (correct) token provided.
    // NOTE Change the 403 code (maybe you get a 401) and the '/refresh' endpoint to fit the backend.

    const fetchError = result.error as FetchBaseQueryError
    const status = fetchError?.status

    // Refresh token logic
    // NOTE TEMP I get 401 when the JWT expires. Also keep 401 here as I don't have a refresh token yet.

    // NOTE PREV if (result?.error?.originalStatus === 403 || result?.error?.originalStatus === 401) {
    if (status === 403 || status === 401) {
        // const accessToken = localStorage.getItem('access_token')
        const refreshToken = localStorage.getItem('refresh_token')

        // const refreshToken = (getState() as RootState).auth.token

        // WIP delete access_token at this point
        // localStorage.removeItem('access_token')

        // dispatch(setUser(data.user))

        // WIP
        // `authorization` header already set
        const refreshResult: any = await baseQuery(
            {
                url: '/refresh',
                method: 'POST',
                body: { refresh_token: `${refreshToken}` },
            },
            api,
            extraOptions
        )

        // WIP test this
        if (refreshResult.data) {
            // same user, new credentials
            const user = api.getState().auth.user
            const newAccessToken = refreshResult.data[0].access_token
            const newRefreshToken = refreshResult.data[0].refresh_token

            api.dispatch(
                setCredentials({
                    access_token: newAccessToken,
                    refresh_token: newRefreshToken,
                    user,
                })
            )

            // write to local storage
            localStorage.setItem('access_token', newAccessToken)
            localStorage.setItem('refresh_token', newRefreshToken)

            // retry the original query, with new access token
            result = await baseQuery(args, api, extraOptions)
        } else {
            // if we don't get a 403 (or 401), log out
            api.dispatch(logOut({ user: undefined, access_token: null, refresh_token: null }))

            // clear the local storage
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
        }
    }

    // if everything goes well, return the result of the query
    return result
}

export const apiSlice = createApi({
    reducerPath: 'api',
    // baseQuery: fetchBaseQuery({ baseUrl: config.katApiUrl }),
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Offer', 'Kubeark'],
    endpoints: (builder) => ({
        // auth
        login: builder.query({
            query: () => '/kat-login',
        }),

        // version
        version: builder.query({
            query: () => '/version',
        }),

        // offers
        getOffers: builder.query({
            query: () => '/offers',
            providesTags: ['Offer'],
        }),
        getOffer: builder.query({
            // query: (offerId) => `/offers/${offerId}`,
            query: (id: number) => `/offers/${id}`,
        }),
        addNewOffer: builder.mutation({
            query: (initialOffer) => ({
                url: `/offers`,
                method: 'POST',
                body: initialOffer,
            }),
            invalidatesTags: ['Offer'],
        }),
        updateOffer: builder.mutation({
            // query: ({ initialOffer, offerId }) => ({
            query: (updatedOffer) => ({
                url: `/offers`,
                method: 'PUT',
                body: updatedOffer,
            }),
            invalidatesTags: ['Offer'],
        }),
        // deleteOffer: builder.mutation({
        //     query: (offerId) => ({
        //         url: `/offers/${offerId}`,
        //         method: 'DELETE',
        //     }),
        //     invalidatesTags: ['Offer'],
        // }),
        deleteOffer: builder.mutation({
            query: (id: number) => ({
                url: `/offers/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Offer'],
        }),

        // orders
        getOrders: builder.query({
            query: () => '/orders',
        }),

        // kubeark instances

        getKubearkInstances: builder.query({
            query: () => '/kubeark',
            providesTags: ['Kubeark'],
        }),
        getKubearkInstance: builder.query({
            query: (id: number) => `/kubeark/${id}`,
        }),
        getKubearkInstanceClusters: builder.query({
            query: (id: number) => `/kubeark/${id}/clusters`,
        }),

        // NOTE Replaced the `query` with a `mutation` as I had to provide a `body` with the GET requet and it was not supported.
        // Notice the contradiction in the generated hook name `get...Mutation`.
        // The `query` function supports only one param, so use an object for multiple.
        getKubearkInstanceTemplates: builder.mutation({
            query: ({ id, perPage }) => ({
                url: `/kubeark/${id}/templates`,
                method: 'POST',
                body: {
                    per_page: perPage ?? 200,
                },
            }),
        }),

        // NOTE Again, a POST request to get data because I need to provide a body to the requet. See above.
        getKubearkInstanceTemplateVersions: builder.mutation({
            query: ({ id, perPage, parentTemplateId }) => ({
                url: `/kubeark/${id}/templates`,
                method: 'POST',
                body: {
                    per_page: perPage ?? 200,
                    parent_template_id: parentTemplateId,
                },
            }),
        }),

        addNewKubearkInstance: builder.mutation({
            query: (kubearkInstance) => ({
                url: `/kubeark`,
                method: 'POST',
                body: kubearkInstance,
            }),
            invalidatesTags: ['Kubeark'],
        }),
        updateKubearkInstance: builder.mutation({
            query: (updatedKubearkInstance) => ({
                url: `/kubeark`,
                method: 'PUT',
                body: updatedKubearkInstance,
            }),
            invalidatesTags: ['Kubeark'],
        }),
        deleteKubearkInstance: builder.mutation({
            query: (id) => ({
                url: `/kubeark/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Kubeark'],
        }),
    }),
})

export const {
    // auth
    useLoginQuery,

    // version
    useVersionQuery,

    // offers
    useGetOffersQuery,
    useGetOfferQuery,
    useAddNewOfferMutation,
    useUpdateOfferMutation,
    useDeleteOfferMutation,

    // orders
    useGetOrdersQuery,

    // kubeark instances
    useGetKubearkInstancesQuery,
    useGetKubearkInstanceQuery,
    useGetKubearkInstanceClustersQuery,
    useGetKubearkInstanceTemplatesMutation,
    useGetKubearkInstanceTemplateVersionsMutation,
    useAddNewKubearkInstanceMutation,
    useUpdateKubearkInstanceMutation,
    useDeleteKubearkInstanceMutation,

    // prefetch (from RTKQ)
    usePrefetch,
} = apiSlice
