// TODO fix types

import { IOrder } from '../core-types'

/**
 * Get an order from an array of orders.
 *
 * @param id Order Id
 * @param orders Array of orders
 */
export function getOrderFromOrders(id: number, orders: IOrder[]): IOrder | undefined {
    return orders.find((order: IOrder) => {
        return order.id === id
    })
}
