import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

const useAuthSearchParams = () => {
    const dispatch = useDispatch()

    // url search params
    const [searchParams] = useSearchParams()

    // get the tokens URL search params and save them to local storage
    useEffect(() => {
        const access_token = searchParams.get('access_token')
        const refresh_token = searchParams.get('refresh_token')

        if (access_token && refresh_token) {
            localStorage.setItem('access_token', access_token)
            localStorage.setItem('refresh_token', refresh_token)
        }
    }, [dispatch, searchParams])

    return {}
}

export default useAuthSearchParams
