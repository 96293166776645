import { MailOutlined } from '@ant-design/icons'
import { Button, Form, Input, Typography } from 'antd'

import './pageRecoverPassword.css'
import { Link } from 'react-router-dom'

const { Text } = Typography

export const PageRecoverPassword: React.FC = () => {
    const onFinish = (values: any) => {}

    return (
        <div className="p-12 -mt-24 text-center rounded-lg shadow-xl forgot-password-page w-80">
            <Text type="secondary">Enter your email to reset your password.</Text>
            <Form
                name="normal_login"
                className="text-left signup-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                style={{ marginTop: '28px' }}
            >
                <Form.Item name="email" rules={[{ required: true, message: 'Please input a valid email address!' }]}>
                    <Input
                        prefix={<MailOutlined className="site-form-item-icon" />}
                        placeholder="Your email address for this account"
                    />
                </Form.Item>

                <Form.Item style={{ marginTop: '48px' }}>
                    <Button type="primary" htmlType="submit" className="block w-full login-form-button">
                        Reset Password
                    </Button>
                </Form.Item>

                <Form.Item>
                    <div className="mt-2">
                        <Link to={`/login`}>Back</Link> to <span className="font-semibold">Log in</span> page.
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}
