/*
    Toolbar: search input, sort dropdown
*/

import { Space, Input } from 'antd'

type Props = {
    onSearchInputChange: any
}

const Toolbar = ({ onSearchInputChange }: Props) => (
    <div className="my-4">
        <Space size="large">
            {/* search input */}
            <Input
                placeholder="Search all"
                allowClear
                onChange={onSearchInputChange}
                className="w-full min-w-[280px]"
            />
        </Space>
    </div>
)

export default Toolbar
