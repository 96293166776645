import { Link } from 'react-router-dom'

const PageNotFound = () => {
    return (
        <div className="flex items-center justify-center h-screen">
            <div className="inline-block px-16 py-8 font-sans text-red-400 shadow-lg rounded-xl bg-red-50">
                <p>
                    <span className="font-semibold">Error</span>
                    <br />
                    Page does not exist!
                </p>
                <p className="mt-4">
                    Go to the{' '}
                    <Link to="/login" className="font-semibold text-red-400">
                        Homepage
                    </Link>
                </p>
            </div>
        </div>
    )
}

export default PageNotFound
