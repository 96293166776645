import { useCallback } from 'react'

const useMainHeaderOffersList = (updateIsOpenDrawerOfferOverview: any) => {
    // new offer clicked
    const onNewOfferClick = useCallback(() => {
        updateIsOpenDrawerOfferOverview(true)
    }, [updateIsOpenDrawerOfferOverview])

    return {
        onNewOfferClick,
    }
}

export default useMainHeaderOffersList
