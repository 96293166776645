import { z } from 'zod'

// Validation schema (Zod)
export const validationSchemaKubeark = z.object({
    name: z
        .string({
            required_error: 'Please provide an instance name.',
        })
        .regex(/^[a-zA-Z0-9-_]*$/, {
            message: 'The name should contain only letters, numbers, underscore (_) or dash (-) characters.',
        })
        .min(1, {
            message: 'Please provide a name.',
        })
        .max(250, { message: 'This name is too long.' }),
    kubeark_url: z
        .string({
            required_error: 'Please provide a Kubeark URL.',
        })
        .min(1, {
            message: 'Please provide a Kubeark URL.',
        })
        .startsWith('https://', {
            message: 'The URL has to start with "https://".',
        })
        .max(1000, { message: 'This URL is too long.' }),
    kubeark_access_token: z
        .string({
            required_error: 'Please provide a Kubeark Access Token.',
        })
        .min(1, {
            message: 'Please provide a Kubeark Access Token.',
        })
        .max(1000, { message: 'This access token is too long.' }),
})
