// antd
import { Spin, Tabs } from 'antd'
import { FileOutlined, LoadingOutlined } from '@ant-design/icons'
// components
import MainHeaderOfferDetails from './MainHeaderOfferDetails'
import TabOverview from './TabOverview'
// custom hooks
import { useSyncActiveTabAndSearchParams } from '../../hooks/useSyncActiveTabAndSearchParams'
import useMainHeaderOfferDetails from '../hooks/useMainHeaderOfferDetails'
import { useParams } from 'react-router-dom'
import { useGetOffersQuery } from '../../api/apiSlice'
import { useCurrentOffer } from '../hooks/useCurrentOffer'
import { useMemo } from 'react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

export const ViewOfferDetails = () => {
    // HOOKS

    const { id } = useParams()

    if (!id) throw new Error(`No offer id.`)

    // offers query
    const offersQuery = useGetOffersQuery({})

    // current offer
    const currentOffer = useCurrentOffer(Number(id), offersQuery)

    // sync tabs and search params
    const { activeTabKey, onChangeTabs } = useSyncActiveTabAndSearchParams({ defaultTabKey: 'offer' })

    // main header
    const { onDeleteOfferClick, isLoadingDelete } = useMainHeaderOfferDetails(Number(id))

    // JSX
    const tabs = useMemo(
        () => [
            // Offer Overview
            {
                label: (
                    <span>
                        <FileOutlined />
                        <span>Offer</span>
                    </span>
                ),
                key: 'offer',
                children: <TabOverview currentOffer={currentOffer!} />,
            },
            // Landing page
            // {
            //     label: (
            //         <span>
            //             <PicCenterOutlined />
            //             <span>Landing Page</span>
            //         </span>
            //     ),
            //     key: 'landing-page',
            //     children: <TabLandingPage currentOffer={currentOffer!} />,
            // },
        ],
        [currentOffer]
    )
    return (
        <div className="w-full view-body">
            <MainHeaderOfferDetails
                id={Number(id)}
                onDeleteOfferClick={onDeleteOfferClick}
                isLoadingDelete={isLoadingDelete}
            />
            {/* loading */}
            {offersQuery.isLoading && (
                <div className="mt-2">
                    <Spin indicator={<LoadingOutlined className="text-sm" spin />} />
                </div>
            )}

            {/* Success, Tabs */}
            {offersQuery.isSuccess && currentOffer && (
                <Tabs
                    className="mt-4"
                    defaultActiveKey="0"
                    activeKey={activeTabKey}
                    onChange={onChangeTabs}
                    items={tabs.map((tab: any) => {
                        return tab
                    })}
                />
            )}

            {/* error */}
            {offersQuery.isError && (
                <p className="text-red-500">
                    <span className="font-semibold">Error {(offersQuery.error as FetchBaseQueryError).status}</span>{' '}
                    Cannot fetch offer details.
                </p>
            )}
        </div>
    )
}
