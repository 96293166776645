import { useCallback, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Input, Button, Modal, Space, Divider, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAddNewKubearkInstanceMutation, useGetKubearkInstancesQuery } from '../api/apiSlice'
import { validationSchemaKubeark } from '../../validations/kubeark'
import { ModalConfirmExit } from './modalConfirmExit'
import { isKubearkInstanceNameTaken } from '../../utilities/kubeark'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'

const { Title, Text } = Typography

// Error modal
const showErrorModal = (status: number | string) => {
    Modal.error({
        content: (
            <span className="text-red-500">
                <span className="font-semibold">Error {status}</span>
                <br />
                Cannot add Kubeark instance!
            </span>
        ),
        okType: 'danger',
        icon: null,
        centered: true,
    })
}

type FormValues = {
    name: string
    kubeark_url: string
    kubeark_access_token: string
}

type ControllerRenderProps = {
    field: any
    fieldState: any
    formState: any
}

// settings for the form control
const formSettings = {
    name: {
        maxLength: 250,
    },
    kubeark_url: {
        maxLength: 1000,
    },
    kubeark_access_token: {
        maxLength: 1000,
    },
}

// custom hook for main queries logic
const useMainQueries = () => {
    const kinstancesQuery = useGetKubearkInstancesQuery({})

    return {
        kinstancesQuery,
    }
}

export const ViewKubearkDetailsAdd = () => {
    // HOOKS

    // Router
    const navigate = useNavigate()

    const { kinstancesQuery } = useMainQueries()

    // React Hook Form
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FormValues>({ resolver: zodResolver(validationSchemaKubeark) })

    const [currentKInstanceName, setCurrentKInstanceName] = useState<string | null>(null)
    const [showDuplicatedNameError, setShowDuplicatedNameError] = useState(false)
    const [nameAlreadyExists, setNameAlreadyExists] = useState(false)

    // Confirm Exit Modal
    const [showConfirmQuitModal, setShowConfirmQuitModal] = useState(false)

    // Mutations
    const [addKubearkInstance, addKubearkInstanceMutation] = useAddNewKubearkInstanceMutation()

    // k instance successfully added, navigate back to the list
    useEffect(() => {
        addKubearkInstanceMutation.isSuccess && navigate('/kubeark')
    }, [addKubearkInstanceMutation.isSuccess, navigate])

    // error adding instance, show error modal and stay on page
    useEffect(() => {
        addKubearkInstanceMutation.isError &&
            addKubearkInstanceMutation.error &&
            showErrorModal((addKubearkInstanceMutation.error as FetchBaseQueryError).status)
    }, [addKubearkInstanceMutation.isError, addKubearkInstanceMutation.error])

    // if the k instances are ready, and I have a current k instance name (onBlur event occured after typing the name), check if the name already exists
    useEffect(() => {
        if (kinstancesQuery.isSuccess && currentKInstanceName) {
            if (isKubearkInstanceNameTaken(currentKInstanceName, kinstancesQuery.data)) {
                setNameAlreadyExists(true)
            } else {
                setNameAlreadyExists(false)
            }
        }
    }, [kinstancesQuery.isSuccess, kinstancesQuery.data, currentKInstanceName, nameAlreadyExists])

    // HANDLERS

    // Confirm Exit Modal

    const onBackArrowClick = useCallback(() => {
        setShowConfirmQuitModal(true)
    }, [])

    const onOkModalExit = useCallback(() => {
        navigate('/kubeark')
    }, [navigate])

    const onCancelModalExit = useCallback(() => {
        setShowConfirmQuitModal(false)
    }, [])

    // submit the form
    const onSubmit = useCallback(
        async (data: any) => {
            try {
                await addKubearkInstance(data)
            } catch (error) {
                throw new Error(`Cannot add Kubeark Instance`)
            }
        },
        [addKubearkInstance]
    )

    // JSX

    // name

    // NOTE React Hook Form - Example with multiple field and fieldState props
    const renderName = useCallback(({ field: { onChange, onBlur, value } }: ControllerRenderProps): JSX.Element => {
        return (
            <Input
                className="max-w-[280px]"
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(ev)
                    // hide the duplicate name error while editing
                    setShowDuplicatedNameError(false)
                }}
                onBlur={(ev: React.FocusEvent<HTMLInputElement>) => {
                    onBlur()
                    const name = ev.target.value

                    // if the kubeark name field in not empty
                    if (name) {
                        setShowDuplicatedNameError(true)
                        setCurrentKInstanceName(name)
                    }
                }}
                value={value}
                maxLength={formSettings['name'].maxLength}
            />
        )
    }, [])

    // kubeark_url

    const renderKubearkUrl = useCallback(
        ({ field: { onChange, value } }: ControllerRenderProps) => (
            <Input
                className="max-w-[280px]"
                onChange={onChange}
                value={value}
                maxLength={formSettings['kubeark_url'].maxLength}
            />
        ),
        []
    )

    // kubeark_access_token

    const renderKubearkAccessToken = useCallback(
        ({ field: { onChange, value } }: ControllerRenderProps) => (
            <Input
                className="max-w-[280px]"
                onChange={onChange}
                value={value}
                maxLength={formSettings['kubeark_access_token'].maxLength}
            />
        ),
        []
    )

    return (
        <div className="w-full">
            {/* Confirm Exit Modal */}
            <ModalConfirmExit open={showConfirmQuitModal} onOk={onOkModalExit} onCancel={onCancelModalExit} />

            {/* View Header */}

            <header className="grid grid-cols-[max-content_minmax(0,_1fr)_minmax(0,_1fr)] grid-rows-[max-content]">
                {/* arrow */}
                <div className="self-center">
                    <a onClick={onBackArrowClick} className="ml-[-32px] w-[32px]">
                        <ArrowLeftOutlined className="text-[18px]" />
                    </a>
                </div>

                {/* left side */}
                <div className="self-center">
                    <Title level={2} className="!my-0">
                        New Instance
                    </Title>
                </div>

                {/* right side */}
                <div className="self-center text-right">
                    <Space size="small">
                        <Space size="large">
                            <Link to={`/kubeark`}>
                                <Button disabled={addKubearkInstanceMutation.isLoading}>Cancel</Button>
                            </Link>
                        </Space>
                        <Button
                            // htmlType="submit"
                            onClick={handleSubmit(onSubmit)}
                            type="primary"
                            loading={addKubearkInstanceMutation.isLoading}
                            // disable this button if the K instance name is duplicated (and the error message is shown)
                            // Tip: I've used the double bang operator `!!` to convert a truthy value (a name) to boolean.
                            disabled={
                                (showDuplicatedNameError && nameAlreadyExists) ||
                                (!!currentKInstanceName && kinstancesQuery.isFetching)
                            }
                        >
                            Add Instance
                        </Button>
                    </Space>
                </div>

                <div></div>
                <div>
                    <Text type="secondary">Create a new Kubeark instance.</Text>
                </div>
            </header>

            <Divider />

            <Form className="flex flex-col" disabled={addKubearkInstanceMutation.isLoading}>
                {/* columns */}

                <section className="flex flex-col md:flex-row">
                    {/* column 1 */}

                    <div className="w-full mr-4 md:w-1/2">
                        {/* name */}
                        <div className="mt-4">
                            <div className="text-gray-400 ">
                                <span className="mr-4">Name</span>
                            </div>
                            <div className="my-0">
                                <Controller name="name" defaultValue={''} control={control} render={renderName} />
                            </div>
                            <div className="text-xs text-orange-500 mt-1 min-h-[16px]">
                                {/* validation error */}
                                {errors.name && errors.name.message}

                                {/* name alread exists (async checked, not part of validation) */}
                                {kinstancesQuery.isFetching && currentKInstanceName && (
                                    <span className="text-blue-500"> Checking Kubeark name... </span>
                                )}
                                {showDuplicatedNameError && nameAlreadyExists && (
                                    <span className="text-red-500">This Kubeark name already exists!</span>
                                )}
                            </div>
                        </div>

                        {/* Kubeark URL */}
                        <div className="mt-4">
                            <div className="text-gray-400 ">
                                <span className="mr-4">Kubeark URL</span>
                            </div>
                            <div className="my-0">
                                <Controller name="kubeark_url" control={control} render={renderKubearkUrl} />
                            </div>
                            <div className="text-xs text-orange-500 mt-1 min-h-[16px]">
                                {errors.kubeark_url && errors.kubeark_url.message}
                            </div>
                        </div>

                        {/* Kubeark Access Token */}
                        <div className="mt-4">
                            <div className="text-gray-400 ">
                                <span className="mr-4">Kubeark Access Token</span>
                            </div>
                            <div className="my-0">
                                <Controller
                                    name="kubeark_access_token"
                                    control={control}
                                    render={renderKubearkAccessToken}
                                />
                            </div>
                            <div className="text-xs text-orange-500 mt-1 min-h-[16px]">
                                {errors.kubeark_access_token && errors.kubeark_access_token.message}
                            </div>
                        </div>
                    </div>

                    {/* column 2 */}

                    <div className="w-full md:w-1/2"></div>
                </section>
            </Form>
        </div>
    )
}
