import { useEffect, useState } from 'react'
import { convertOfferDataToIOffer, getOfferFromOffers } from '../../../utilities/offers'
import type { IOffer } from '../../../core-types'

export const useCurrentOffer = (id: number, offersQuery: any): IOffer | undefined => {
    // current offer data
    const [currentOffer, setCurrentOffer] = useState<IOffer | undefined>(undefined)

    // set the current offer when the offers are fetched
    useEffect(() => {
        if (offersQuery.isSuccess) {
            let offerData = getOfferFromOffers(id, offersQuery.data)

            if (offerData) {
                const offer = convertOfferDataToIOffer(offerData) // convert the data to the right format
                setCurrentOffer(offer as IOffer)
            }
        }
    }, [offersQuery.isSuccess, offersQuery.data, id])

    return currentOffer
}
