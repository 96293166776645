import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Select, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { svgIconApi, svgIconDate, svgIconInfo, svgIconInstance } from '../../svgs'
import { getClusterFromClusters } from '../../utilities/kubeark'
import { useGetKubearkInstanceClustersQuery } from '../api/apiSlice'

import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { IKCluster, IKInstance } from '../../core-types'
import { getOptionFromSelectOptions } from '../../utilities/tools'
import { useSearchParams } from 'react-router-dom'

type Props = {
    kubearkInstance: IKInstance
}

const useMainQueries = (kubearkInstanceId: number) => {
    const kclustersQuery = useGetKubearkInstanceClustersQuery(kubearkInstanceId)

    // filter the active clusters
    const activeClusters: IKCluster[] = useMemo(() => {
        return kclustersQuery.isSuccess && kclustersQuery.data.filter((cluster: IKCluster) => cluster.active)
    }, [kclustersQuery.isSuccess, kclustersQuery.data])

    // options for <Select>
    const optionsActiveClusters = useMemo(() => {
        if (kclustersQuery.isSuccess) {
            return activeClusters.map((cluster: IKCluster) => ({
                value: cluster.id,
                label: cluster.name,
            }))
        } else {
            return []
        }
    }, [activeClusters, kclustersQuery.isSuccess])

    return {
        kclustersQuery,
        activeClusters,
        optionsActiveClusters,
    }
}

const Clusters: React.FC<Props> = ({ kubearkInstance }) => {
    // HOOKS

    // router
    const [searchParams, setSearchParams] = useSearchParams()

    // current selected cluster from the Select dropdown or from the `cluster` search param
    const [currentCluster, setCurrentCluster] = useState<IKCluster | undefined>(undefined)

    // main query
    const { kclustersQuery, activeClusters, optionsActiveClusters } = useMainQueries(kubearkInstance.id)

    // Set the selected cluster and the `cluster` search param if it doesn't exist
    useEffect(() => {
        if (activeClusters) {
            if (searchParams && searchParams.get('cluster')) {
                // read the `cluster` search param and set the selected cluster to it
                const clusterId = searchParams.get('cluster')
                if (typeof clusterId === 'string') {
                    setCurrentCluster(getClusterFromClusters(Number(clusterId), activeClusters))
                }
            } else {
                // if no cluster is selected, take the first cluster in the array
                setCurrentCluster(activeClusters[0])
                setSearchParams({
                    ...Object.fromEntries([...searchParams]),
                    cluster: String(activeClusters[0].id),
                })
            }
        }
    }, [searchParams, activeClusters, setSearchParams])

    // HANDLERS

    // On select cluster
    // Set the selected cluster and update the `cluster`  search param.
    const handleSelectClusterChange = useCallback(
        (value: string) => {
            const cluster = getClusterFromClusters(Number(value), activeClusters) as IKCluster
            setCurrentCluster(cluster)
            setSearchParams({
                ...Object.fromEntries([...searchParams]),
                cluster: String(cluster!.id),
            })
        },
        [activeClusters, setCurrentCluster, searchParams, setSearchParams]
    )

    // JSX

    return (
        <div className="flex-grow">
            {/* Loading (also fetching) */}
            {kclustersQuery.isLoading && (
                <div className="mt-2">
                    <Spin indicator={<LoadingOutlined className="text-sm" spin />} />
                </div>
            )}

            {/* success  */}
            {kclustersQuery.isSuccess && (
                <div className="flex flex-col ">
                    {/*  header */}
                    <header className="flex items-center py-4 ">
                        {/* side left */}
                        <div className="flex items-center h-full">
                            <div>
                                <div className="leading-none text-gray-400 ">Cluster</div>
                                {optionsActiveClusters && currentCluster && (
                                    <Select
                                        defaultValue={
                                            getOptionFromSelectOptions(currentCluster.id, optionsActiveClusters)?.label
                                        }
                                        className="w-[280px] mt-1"
                                        onChange={handleSelectClusterChange}
                                        options={optionsActiveClusters}
                                    />
                                )}
                            </div>
                        </div>

                        {currentCluster && (
                            <div className="flex">
                                {/* kubeark instance */}
                                <div className="pl-4 ml-8 border-0 border-l border-gray-100 border-solid">
                                    <div className="flex items-center text-gray-400">
                                        <div className="w-4 h-4">{svgIconInstance}</div>
                                        <div className="ml-2 leading-none">Instance</div>
                                    </div>
                                    <div className="mt-1 ml-6">
                                        <a
                                            className="!text-gray-500 hover:!text-gray-800 !underline"
                                            href={kubearkInstance?.kubeark_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {kubearkInstance?.name}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* side right */}
                        {currentCluster && (
                            <div className="flex items-center justify-end flex-grow">
                                {/* active badge */}
                                {currentCluster.active && (
                                    <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800 ml-4 shadow-sm">
                                        <svg
                                            className="-ml-1 mr-1.5 h-2 w-2 text-green-400"
                                            fill="currentColor"
                                            viewBox="0 0 8 8"
                                        >
                                            <circle cx={4} cy={4} r={3} />
                                        </svg>
                                        Active
                                    </span>
                                )}
                                {/* inactive badge */}
                                {!currentCluster.active && (
                                    <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800 ml-4 shadow-sm">
                                        <svg
                                            className="-ml-1 mr-1.5 h-2 w-2 text-gray-400"
                                            fill="currentColor"
                                            viewBox="0 0 8 8"
                                        >
                                            <circle cx={4} cy={4} r={3} />
                                        </svg>
                                        Inactive
                                    </span>
                                )}
                            </div>
                        )}
                    </header>

                    {currentCluster && (
                        <section className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-[max-content_max-content_max-content] px-4 py-4 pb-6 rounded-lg shadow-md bg-slate-50">
                            {/* column one */}
                            <div className="pr-16 mr-8 border-0 border-solid lg:border-r border-slate-200">
                                {/* name and description */}
                                <div className="mt-4 font-semibold leading-none text-gray-500">
                                    {currentCluster?.name}
                                </div>
                                <div className="my-0 text-gray-400">
                                    {currentCluster?.description || <i>No description.</i>}
                                </div>

                                {/* details */}
                                <div className="mt-4">
                                    <div className="leading-none text-gray-400 ">Details</div>
                                    {currentCluster?.details || <i className="text-gray-400">No details.</i>}
                                </div>

                                {/* owner */}
                                <div className="mt-4">
                                    <div className="leading-none text-gray-400 ">Owner</div>
                                    <div className="my-0 text-gray-800">{currentCluster?.owner}</div>
                                </div>

                                {/* prometheus url */}
                                <div className="mt-4">
                                    <div className="leading-none text-gray-400 ">Prometheus URL</div>
                                    <div className="my-0 text-gray-800">
                                        <a
                                            className="!text-gray-500 hover:!text-gray-800 text-sm"
                                            href={currentCluster?.prometheus_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {currentCluster?.prometheus_url ? (
                                                currentCluster?.prometheus_url
                                            ) : (
                                                <span className="text-gray-400">none</span>
                                            )}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            {/* column two */}
                            <div className="pr-16 mt-8 mr-8 border-0 border-dashed lg:mt-0 xl:border-r border-slate-200">
                                {/* api table */}
                                <div className="table mt-4">
                                    <div className="w-5 h-5 text-indigo-500">{svgIconApi}</div>

                                    {/* api token */}
                                    <div className="table-row ">
                                        <div className="table-cell py-2 pr-6 border-0 border-b border-gray-200 border-solid">
                                            <div className="leading-none text-gray-400">API Token</div>
                                        </div>
                                        <div className="table-cell py-2 border-0 border-b border-gray-200 border-solid">
                                            <div className="text-gray-800">
                                                {currentCluster?.api_token ? (
                                                    currentCluster?.api_token
                                                ) : (
                                                    <span className="text-gray-400">none</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* api server */}
                                    <div className="table-row">
                                        <div className="table-cell py-2 pr-6 border-0 border-b border-gray-200 border-solid">
                                            <div className="leading-none text-gray-400 ">API Server</div>
                                        </div>
                                        <div className="table-cell py-2 border-0 border-b border-gray-200 border-solid">
                                            <div className="text-gray-800 ">
                                                {currentCluster?.api_server ? (
                                                    currentCluster?.api_server
                                                ) : (
                                                    <span className="text-gray-400">none</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* certificate */}
                                    <div className="table-row">
                                        <div className="table-cell py-2 pr-6">
                                            <div className="leading-none text-gray-400 ">Certificate</div>
                                        </div>
                                        <div className="table-cell py-2">
                                            <div className="text-gray-800 ">
                                                {currentCluster?.certificate ? (
                                                    currentCluster?.certificate
                                                ) : (
                                                    <span className="text-gray-400">none</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* dates */}
                                <div className="mt-8">
                                    <div className="w-5 h-5 text-violet-500">{svgIconDate}</div>

                                    {/* updated, created */}
                                    <div className="mt-4">
                                        {/* updated at */}
                                        <div className="leading-none text-gray-400 ">Updated at</div>
                                        <div className="my-0 text-gray-400">{currentCluster?.updated_at}</div>

                                        {/* created at */}
                                        <div className="mt-4 leading-none text-gray-400">Created at</div>
                                        <div className="my-0 text-gray-400">{currentCluster?.created_at}</div>
                                    </div>
                                </div>
                            </div>

                            {/* column three */}
                            <div className="mt-8 text-left lg:mt-0 ">
                                {/* info */}
                                <div className="table mt-4">
                                    <span className="text-purple-500">{svgIconInfo}</span>

                                    {/* cost */}
                                    <div className="table-row ">
                                        <div className="table-cell py-2 pr-6 border-0 border-b border-gray-200 border-solid">
                                            <div className="leading-none text-gray-400">Cost</div>
                                        </div>
                                        <div className="table-cell py-2 border-0 border-b border-gray-200 border-solid">
                                            <div className="text-gray-600">
                                                {currentCluster?.cost ? (
                                                    currentCluster?.cost?.name
                                                ) : (
                                                    <span className="text-gray-400">none</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* cost url */}
                                    <div className="table-row">
                                        <div className="table-cell py-2 pr-6 ">
                                            <div className="leading-none text-gray-400 ">Cost URL</div>
                                        </div>
                                        <div className="table-cell py-2 ">
                                            <div className="text-gray-600 ">
                                                {currentCluster?.cost_url ? (
                                                    currentCluster?.cost_url
                                                ) : (
                                                    <span className="text-gray-400">none</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}

                    {/* bottom section */}
                    {false && currentCluster && (
                        <section className="flex px-4 py-6 -mt-2 overflow-scroll rounded-lg shadow-md bg-slate-100">
                            {/* config */}
                            <section className="flex-grow pl-4">
                                <div className="font-semibold leading-none text-slate-500">
                                    <div className="">Config</div>
                                </div>

                                <pre
                                    className="overflow-scroll text-xs whitespace-pre-wrap text-slate-500"
                                    style={{ wordWrap: 'break-word' }}
                                >
                                    {JSON.stringify(currentCluster?.config, null, 2)}
                                </pre>
                            </section>
                        </section>
                    )}

                    {/* TODO JSON view  */}
                </div>
            )}

            {/* error  */}
            {kclustersQuery.isError && (
                <div className="my-8">
                    <div className="inline-block p-2 overflow-scroll text-red-500 rounded-md bg-red-50">
                        <div>
                            <span className="font-semibold">
                                Error {(kclustersQuery.error as FetchBaseQueryError).status}
                            </span>{' '}
                            Cannot fetch clusters.
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Clusters
