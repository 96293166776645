import { useCallback, useEffect, useState } from 'react'
// redux
import { useLoginQuery } from '../../api/apiSlice'

/**
 * Login logic using IDP.
 * @returns
 */
const useLogin = () => {
    // HOOKS

    const [skipLogin, setSkipLogin] = useState(true)

    const {
        data: resultLoginQuery,
        isSuccess: isSuccessLoginQuery,
        isLoading: isLoadingLoginQuery,
        isError: isErrorLoginQuery,
        error: errorLoginQuery,
    } = useLoginQuery({}, { skip: skipLogin })

    // login query successfull, redirect to the IDP login page
    useEffect(() => {
        if (isSuccessLoginQuery && resultLoginQuery) {
            console.log('result', resultLoginQuery)

            if (resultLoginQuery?.data?.authorization_url) {
                window.location.href = resultLoginQuery.data.authorization_url
            }
        }
    }, [isSuccessLoginQuery, resultLoginQuery])

    // HANDLERS

    // login click - make a request
    const handleLogin = useCallback(() => {
        setSkipLogin(false)
    }, [setSkipLogin])

    return {
        handleLogin,

        isLoadingLoginQuery,
        isErrorLoginQuery,
        errorLoginQuery,
    }
}

export default useLogin
