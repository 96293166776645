import { Layout, Space, Typography, theme } from 'antd'
import config from '../../../config'
import { useVersionQuery } from '../../api/apiSlice'
const { Footer } = Layout
const { Text } = Typography

const { useToken } = theme

type Props = {}

const MainFooter = (props: Props) => {
    const { token } = useToken()
    // version
    const { data: dataVersion } = useVersionQuery({})

    return (
        <Footer style={{ textAlign: 'center', backgroundColor: token.colorBgContainer }}>
            <Text type="secondary" className="text-xs text-left">
                <Space size="large" wrap>
                    <span>{config.appName}</span>
                    <span>
                        Version {config.version}, API Version {dataVersion?.version ?? <span>...</span>}
                    </span>
                    <span>
                        {config.companyName}, {config.currentYear}
                    </span>
                </Space>
            </Text>
        </Footer>
    )
}

export default MainFooter
