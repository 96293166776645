import React from 'react'
import { Button, Typography } from 'antd'
import { selectCurrentUser } from '../../auth/authSlice'
import { useSelector } from 'react-redux'
import useLogout from '../../groupAuth/hooks/useLogout'

const { Title, Text } = Typography

export const ViewUsersList: React.FC = () => {
    const user = useSelector(selectCurrentUser)

    // NOTE AUTH
    const { handleLogout } = useLogout()

    // JSX
    return (
        <div className="w-full view-body">
            <Title level={2} className="!my-0">
                Users
            </Title>
            <Text type="secondary">Your account.</Text>

            <div>
                <div className="my-6">
                    <p>
                        <span className="text-gray-400">User</span> {user}
                    </p>
                </div>
                <div className="mt-8">
                    <Button size="small" onClick={handleLogout}>
                        Sign Out
                    </Button>
                </div>
            </div>
        </div>
    )
}
