import { Outlet } from 'react-router-dom'
import './rootLayout.css'
import { ConfigProvider, theme } from 'antd'
import { createContext, useMemo, useState } from 'react'

// Define the type for the context value object
interface ThemeContextType {
    theme: string
    setTheme: React.Dispatch<React.SetStateAction<string>>
}

export const ThemeContext = createContext<ThemeContextType | null>(null)

const kubeLightTheme = {
    algorithm: theme.defaultAlgorithm,
    token: {
        colorPrimary: '#870bff',
        colorLink: '#870bff',

        colorTextBase: '#0c043a',
        colorLinkHover: '#a033ff',
        colorLinkActive: '#6500d9',
    },
    components: {
        // Radio: {
        //     colorPrimary: '#00b96b',
        // },
        // Checkbox: {
        //     colorPrimary: '#00b96b',
        // },
    },
}

const kubeDarkTheme = {
    algorithm: theme.darkAlgorithm,
    token: {
        colorPrimary: '#870bff',
        colorLink: '#870bff',

        colorTextBase: '#e7e6f4',
        // colorBgContainer: '#060318',

        colorLinkHover: '#a033ff',
        colorLinkActive: '#6500d9',
    },
}

export const themes: any = {
    kubeLightTheme: kubeLightTheme,
    kubeDarkTheme: kubeDarkTheme,
}

const RootLayout = () => {
    const [theme, setTheme] = useState('kubeLightTheme')

    const currentTheme = useMemo(
        () => ({
            theme,
            setTheme,
        }),
        [theme]
    )

    return (
        <ThemeContext.Provider value={currentTheme}>
            <ConfigProvider theme={themes[theme]}>
                <Outlet />
            </ConfigProvider>
        </ThemeContext.Provider>
    )
}

export default RootLayout
