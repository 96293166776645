import { Divider, Typography } from 'antd'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { IKInstance, IOffer, IOrder } from '../../../core-types'
import { prettyDate } from '../../../utilities/tools'
import { useGetKubearkInstancesQuery, useGetOffersQuery, useGetOrdersQuery } from '../../api/apiSlice'
import './viewDashboard.css'

const { Title, Text } = Typography

// custom hook for main queries
const useMainQueries = () => {
    const offersQuery = useGetOffersQuery({})
    const ordersQuery = useGetOrdersQuery({})
    const kinstancesQuery = useGetKubearkInstancesQuery({})

    // Make sure I don't mutate the state.
    // Sort offers descending by updated_at date.
    const sortedOffers = useMemo(() => {
        if (offersQuery.isSuccess) {
            // shallow copy
            const sorted = offersQuery.data.slice()
            sorted.sort((a: IOffer, b: IOffer) => b.updated_at.localeCompare(a.updated_at))
            return sorted
        }
    }, [offersQuery.data, offersQuery.isSuccess])

    // Sort orders descending by updated_at date.
    const sortedOrders = useMemo(() => {
        if (ordersQuery.isSuccess) {
            // shallow copy
            const sorted = ordersQuery.data.slice()
            sorted.sort((a: IOrder, b: IOrder) => b.updated_at.localeCompare(a.updated_at))
            return sorted
        }
    }, [ordersQuery.data, ordersQuery.isSuccess])

    // sort by name (don't really need this here, maybe in the future)
    const sortedKubearkInstances = useMemo(() => {
        if (kinstancesQuery.isSuccess) {
            const sorted = kinstancesQuery.data.slice()
            sorted.sort((a: IKInstance, b: IKInstance) => b.name > a.name)
            return sorted
        }
    }, [kinstancesQuery.data, kinstancesQuery.isSuccess])

    return {
        sortedOffers,
        sortedOrders,
        sortedKubearkInstances,
    }
}

// temp
// const users: IUser | null = null

export const ViewDashboard = () => {
    // HOOKS

    const { sortedOffers, sortedOrders, sortedKubearkInstances } = useMainQueries()

    /*
        NOTE Tailwind CSS - I have to hardcode the Tailwind class names here (Ex.:`bg-blue-50`),
        so the post CSS processor can find them at build time.
        Can't generate them from JS (Ex.: `bg-${item.color}-50`), they won't be found and included.
    */
    const widgetsData = useMemo(
        () => [
            {
                title: 'Offers',
                link: '/offers',
                data: sortedOffers,
                value: sortedOffers ? sortedOffers?.length : '-',
                secondaryTitle: 'Latest Update',
                secondaryValue:
                    sortedOffers && sortedOffers.length > 0 ? prettyDate(new Date(sortedOffers[0].updated_at)) : '-',
                // class names (hardcoded)
                classOpacity: sortedOffers ? 'opacity-100' : 'opacity-10',
                classShadow: 'shadow-sky-100',
                classShadowHover: 'hover:shadow-sky-100',
                classBg: 'bg-sky-50',
                classText400: 'text-sky-400',
                classText500: 'text-sky-500',
            },
            {
                title: 'Orders',
                link: '/orders',
                data: sortedOrders,
                value: sortedOrders ? sortedOrders?.length : '-',
                secondaryTitle: 'Latest Update',
                secondaryValue:
                    sortedOrders && sortedOrders.length > 0 ? prettyDate(new Date(sortedOrders[0].updated_at)) : '-',
                // class names (hardcoded)
                classOpacity: sortedOrders ? 'opacity-100' : 'opacity-10',
                classShadow: 'shadow-blue-100',
                classShadowHover: 'hover:shadow-blue-100',
                classBg: 'bg-blue-50',
                classText400: 'text-blue-400',
                classText500: 'text-blue-500',
            },
            {
                title: 'Kubeark Instances',
                link: '/kubeark',
                data: sortedKubearkInstances,
                value: sortedKubearkInstances ? sortedKubearkInstances?.length : '-',
                secondaryTitle: 'Latest Update',
                secondaryValue:
                    sortedKubearkInstances && sortedKubearkInstances.length > 0
                        ? prettyDate(new Date('2022-12-27T10:37:14.224501'))
                        : '-',
                // class names (hardcoded)
                classOpacity: sortedOrders ? 'opacity-100' : 'opacity-10',
                classShadow: 'shadow-indigo-100',
                classShadowHover: 'hover:shadow-indigo-100',
                classBg: 'bg-indigo-50',
                classText400: 'text-indigo-400',
                classText500: 'text-indigo-500',
            },
            // {
            //     title: 'Users',
            //     link: '/users',
            //     data: users,
            //     value: users ? 'x' : '-',
            //     secondaryTitle: 'Latest Update',
            //     secondaryValue: users ? prettyDate(new Date('2022-12-27T10:37:14.224501')) : '-',
            //     // class names (hardcoded)
            //     classOpacity: users ? 'opacity-100' : 'opacity-10',
            //     classShadow: 'shadow-violet-100',
            //     classShadowHover: 'hover:shadow-violet-100',
            //     classBg: 'bg-violet-50',
            //     classText400: 'text-violet-400',
            //     classText500: 'text-violet-500',
            // },
        ],
        [sortedOffers, sortedOrders, sortedKubearkInstances]
    )

    // JSX
    return (
        <div className="view-dashboard">
            {/* view header */}
            <header className="grid grid-cols-[max-content_minmax(0,_1fr)_minmax(0,_1fr)] grid-rows-[max-content]">
                {/* arrow */}
                <div className="self-center"></div>

                {/* left side */}
                <div className="self-center">
                    <Title level={2} className="!my-0">
                        Dashboard
                    </Title>
                </div>
                {/* right side */}
                <div className="self-center text-right"></div>
                <div></div>
                <div>
                    <Text type="secondary">An overview.</Text>
                </div>
            </header>

            <Divider />

            {/* grid */}
            <div className="grid grid-cols-[1fr_1fr] md:grid-cols-[minmax(0,_220px)_minmax(0,_220px)_minmax(0,_220px)] xl:grid-cols-[1fr_1fr_1fr_1fr] gap-4 md:gap-8 max-w-5xl">
                {/* item */}
                {widgetsData.map((item: any) => (
                    <Link to={item.link} key={item.title}>
                        <div
                            className={`shadow-md ${item.classShadow} widget-box ${item.classBg} hover:shadow-xl active:shadow-sm ${item.classShadowHover}`}
                        >
                            <div className={`px-4 py-3 ${item.classText400}`}>{item.title}</div>
                            <div className={`text-[48px] font-semibold leading-none px-4 ${item.classText500}`}>
                                <div className={`my-1 transition-opacity duration-700 ${item.classOpacity}`}>
                                    {item.value}
                                </div>
                            </div>
                            <div className={`px-4 mt-1 text-xs ${item.classText400}`}>
                                <div className={`opacity-70`}>{item.secondaryTitle}</div>
                                <div className={`opacity-80`}>{item.secondaryValue}</div>
                            </div>
                        </div>
                    </Link>
                ))}

                {/* k instances big */}
                {/* <Link to="/kubeark" className="col-span-2">
                    <div className="shadow-md shadow-violet-200 widget-box bg-violet-500 hover:shadow-xl active:shadow-sm hover:shadow-violet-300">
                        <div className="px-4 py-3 text-violet-100">Kubeark Instances</div>
                        <div className="text-[48px] font-semibold leading-none px-4 text-violet-50">
                            <div
                                className={`my-1 transition-opacity duration-700 ${
                                    (!sortedKubearkInstances && 'opacity-10') ||
                                    (sortedKubearkInstances && 'opacity-100')
                                }`}
                            >
                                {(!sortedKubearkInstances && '0') ||
                                    (sortedKubearkInstances && sortedKubearkInstances.length)}
                            </div>
                        </div>
                        <div className="">
                            <div className="px-4 mt-1 text-xs text-violet-100">
                                <div className="opacity-70">Latest Update</div>
                                <div className="opacity-80">{sortedKubearkInstances && prettyDate(new Date())}</div>
                            </div>
                        </div>
                    </div>
                </Link> */}

                {/* news big */}
                {/* <div className="shadow-md shadow-cyan-100 widget-box hover:shadow-xl active:shadow-sm bg-cyan-50 hover:shadow-cyan-100">
                    <div className="p-4">
                        <div className="mr-2 text-cyan-500 opacity-70">Kubeark</div>
                        <div className="mt-4 ">
                            <a
                                href="https://kubeark.com/resources/blog"
                                className="underline text-cyan-500 hover:text-cyan-500 active:text-cyan-500 hover:underline focus:underline"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Blog
                            </a>
                        </div>
                        <div className="mt-2 ">
                            <a
                                href="https://docs.kubeark.com/getting-started/what-is-kubeark"
                                className="underline text-cyan-500 hover:text-cyan-500 active:text-cyan-500 hover:underline focus:underline"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Documentation
                            </a>
                        </div>
                    </div>
                </div> */}

                <div className="col-span-2 shadow-md shadow-violet-200 widget-box hover:shadow-xl active:shadow-sm bg-violet-500 hover:shadow-violet-300">
                    <div className="p-4">
                        <div className="mr-2 text-violet-50 opacity-80">Kubeark</div>
                        <div className="mt-4 ">
                            <a
                                href="https://kubeark.com/resources/blog"
                                className="underline text-violet-50 hover:text-violet-50 active:text-violet-100 hover:underline focus:underline"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Blog
                            </a>
                        </div>
                        <div className="mt-2 ">
                            <a
                                href="https://docs.kubeark.com/getting-started/what-is-kubeark"
                                className="underline text-violet-50 hover:text-violet-50 active:text-violet-100 hover:underline focus:underline"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Public Documentation
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
