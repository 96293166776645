import { Button, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

type Props = {
    offersCount: number
    onNewOfferClick: any
}

const MainHeaderOffersList = ({ offersCount, onNewOfferClick }: Props) => {
    // JSX
    return (
        <header className="grid grid-cols-[max-content_minmax(0,_1fr)_minmax(0,_1fr)] grid-rows-[max-content]">
            {/* arrow */}
            <div className="self-center"></div>

            {/* left side */}
            <div className="self-center">
                <Title level={2} className="!my-0">
                    Offers
                </Title>
            </div>

            {/* right side */}
            <div className="self-center text-right">
                <Button type="primary" icon={<PlusOutlined />} onClick={onNewOfferClick}>
                    New Offer
                </Button>
            </div>

            <div />
            <div>
                <Text type="secondary">{`${offersCount} offers`}</Text>
            </div>
        </header>
    )
}

export default MainHeaderOffersList
