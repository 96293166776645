import { Divider, Form, Input, Select, Spin, Typography } from 'antd'
import useFormOffer from '../hooks/useFormOffer'
import { IOffer } from '../../../core-types'
import useOfferQueries from '../hooks/useOfferQueries'
import useOfferSelectOptions from '../hooks/useOfferSelectOptions'
import { useEffect } from 'react'

const { Text } = Typography

// validators

const validateOfferIdInput = (_: any, value: string) => {
    const regex = /^[a-z_-]*$/
    if (!regex.test(value)) {
        return Promise.reject('Please enter lowercase letters, underscore, or hyphen only!')
    }
    return Promise.resolve()
}

type Props = {
    offer: IOffer | undefined
    setIsDirty: (isDirty: boolean) => void
    offerUpdateMethod: (offerData: any) => Promise<any> // Ex: `addOffer`, `updateOffer` mutations
    updateIsOpenParent: (isOpen: boolean) => void
}

const FormOffer = ({ offer, setIsDirty, offerUpdateMethod, updateIsOpenParent }: Props) => {
    // HOOKS

    const {
        form,
        initialValues,
        handleFormChange,
        onFinish,
        // state
        currentKInstanceId,
        currentKTemplateId,
        // handlers
        onChangeSelectKInstance,
        onChangeSelectKTemplate,
    } = useFormOffer(offer, setIsDirty, offerUpdateMethod, updateIsOpenParent)

    // new offer queries
    const queries = useOfferQueries(currentKInstanceId, currentKTemplateId)

    const options = useOfferSelectOptions(
        queries.queryKInstances,
        queries.mutationKTemplates,
        queries.mutationKTemplateVersions,
        queries.queryKClusters
    )

    // When you get a query error, set the corresponding <Select> value to `null` so the form is invalid
    useEffect(() => {
        queries.mutationKTemplates.isError && form.setFieldValue('template_id', null)
    }, [form, queries.mutationKTemplates.isError])

    useEffect(() => {
        queries.mutationKTemplateVersions.isError && form.setFieldValue('template_version_id', null)
    }, [form, queries.mutationKTemplateVersions.isError])

    useEffect(() => {
        queries.queryKClusters.isError && form.setFieldValue('cluster_id', null)
    }, [form, queries.queryKClusters.isError])

    // JSX

    return (
        <div className="w-full">
            <Spin spinning={queries.queryKInstances.isLoading || queries.mutationKTemplates.isLoading}>
                <Form
                    form={form}
                    name="form_offer"
                    layout="horizontal"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    labelAlign="left"
                    onFinish={onFinish}
                    initialValues={initialValues}
                    onFieldsChange={handleFormChange}
                >
                    {/* offer_id */}
                    <Form.Item
                        label={<span className="opacity-60">Offer Id</span>}
                        name="offer_id"
                        colon={false}
                        extra={
                            <Text type="secondary" className="mt-1 text-xs">
                                If you are testing an offer make sure to add to the end of the offer id <b>-preview</b>.
                                <br />
                                Eg: <i>Saas_offer-preview</i>
                            </Text>
                        }
                        rules={[
                            { required: true, message: 'An offer id is required!' },
                            { max: 250, message: 'Maximum length exceeded!' },
                            { validator: validateOfferIdInput },
                        ]}
                    >
                        <Input placeholder="Offer Id" />
                    </Form.Item>

                    {/* plan_id */}
                    <Form.Item
                        className="my-10"
                        label={<span className="opacity-60">Plan Id</span>}
                        name="plan_id"
                        colon={false}
                        extra={
                            <Text type="secondary" className="mt-1 text-xs">
                                It is advised to have an unique plan id across multiple listings.
                            </Text>
                        }
                        rules={[
                            { required: true, message: 'This field is required!' },
                            { max: 250, message: 'Maximum length exceeded!' },
                        ]}
                    >
                        <Input placeholder="Plan Id" />
                    </Form.Item>

                    {/* Kubeark section */}

                    <Divider className="my-8 bg-white/10" />

                    {/* kubeark instance */}
                    <Form.Item
                        label={<span className="opacity-60">Kubeark Instance</span>}
                        name="kubeark_id"
                        colon={false}
                    >
                        <Select
                            placeholder="Choose a Kubeark instance"
                            onChange={onChangeSelectKInstance}
                            options={options.optionsKInstances}
                            loading={queries.queryKInstances.isLoading}
                            allowClear
                        />
                    </Form.Item>

                    {/* template id */}
                    {currentKInstanceId && (
                        <Form.Item
                            label={<span className="opacity-60">Template</span>}
                            name="template_id"
                            colon={false}
                            rules={[{ required: true, message: 'This field is required!' }]}
                        >
                            <Select
                                placeholder="Choose template"
                                onChange={onChangeSelectKTemplate}
                                options={options.optionsKTemplates}
                                loading={queries.mutationKTemplates.isLoading}
                            />
                        </Form.Item>
                    )}

                    {/* select template version */}
                    {currentKTemplateId && options.optionsKTemplateVersions.length > 0 && (
                        <Form.Item
                            label={<span className="opacity-60">Template Version</span>}
                            name="template_version_id"
                            colon={false}
                        >
                            <Select
                                placeholder="Chose template version"
                                options={options.optionsKTemplateVersions}
                                loading={queries.mutationKTemplateVersions.isLoading}
                                allowClear
                            />
                        </Form.Item>
                    )}

                    {currentKInstanceId && (
                        <Form.Item
                            label={<span className="opacity-60">Cluster</span>}
                            name="cluster_id"
                            colon={false}
                            rules={[{ required: true, message: 'This field is required!' }]}
                        >
                            <Select
                                placeholder="Select a Kubeark instance first."
                                options={options.optionsKClusters}
                                loading={queries.queryKClusters.isLoading}
                            />
                        </Form.Item>
                    )}
                </Form>
            </Spin>
        </div>
    )
}

export default FormOffer
