import { useMemo } from 'react'
import { IOffer } from '../../../core-types'
import { getClusterFromClusters, getTemplateFromTemplates } from '../../../utilities/kubeark'

type QueriesType = {
    queryKInstances: any
    mutationKTemplates: any
    mutationKTemplateVersions: any
    queryKClusters: any
}

const useItemNamesAndLinks = (currentOffer: IOffer | undefined, queries: QueriesType) => {
    // memo - K instance link
    const linkKInstance = useMemo(() => {
        if (currentOffer) {
            const instanceId = currentOffer?.kubeark_id
            return `/kubeark/${instanceId}`
        }
        return ''
    }, [currentOffer])

    // memo - K template link
    const linkKTemplate = useMemo(() => {
        if (queries.queryKInstances.isSuccess && queries.queryKClusters.isSuccess) {
            const instanceId = currentOffer?.kubeark_id

            const templateId = getTemplateFromTemplates(
                currentOffer?.template_id!,
                queries.mutationKTemplates?.data?.templates
            )?.id

            return `/kubeark/${instanceId}?tab=${'templates'}&template=${templateId}`
        }
        return ''
    }, [
        currentOffer?.kubeark_id,
        currentOffer?.template_id,
        queries.queryKClusters.isSuccess,
        queries.queryKInstances.isSuccess,
        queries.mutationKTemplates?.data?.templates,
    ])

    // memo - K template version link
    const linkKTemplateVersion = useMemo(() => {
        if (queries.queryKInstances.isSuccess && queries.queryKClusters.isSuccess) {
            const instanceId = currentOffer?.kubeark_id

            const templateId = getTemplateFromTemplates(
                currentOffer?.template_version_id,
                queries.mutationKTemplateVersions?.data?.templates
            )?.id
            return `/kubeark/${instanceId}?tab=${'templates'}&template=${templateId}`
        }
        return ''
    }, [
        currentOffer?.kubeark_id,
        currentOffer?.template_version_id,
        queries.queryKClusters.isSuccess,
        queries.queryKInstances.isSuccess,
        queries.mutationKTemplateVersions?.data?.templates,
    ])

    // memo - K cluster link
    const linkKCluster = useMemo(() => {
        if (queries.queryKInstances.isSuccess && queries.queryKClusters.isSuccess) {
            const instanceId = currentOffer?.kubeark_id
            const clusterId = getClusterFromClusters(currentOffer?.cluster_id, queries.queryKClusters.data)?.id
            return `/kubeark/${instanceId}?tab=${'clusters'}&cluster=${clusterId}`
        }

        return ''
    }, [
        currentOffer?.cluster_id,
        currentOffer?.kubeark_id,
        queries.queryKClusters.data,
        queries.queryKClusters.isSuccess,
        queries.queryKInstances.isSuccess,
    ])

    return {
        linkKInstance,
        linkKTemplate,
        linkKTemplateVersion,
        linkKCluster,
    }
}

export default useItemNamesAndLinks
