import { Tag } from 'antd'

export const DeploymentStatus = ({ status }: { status: string | null }) => {
    switch (status?.toLowerCase()) {
        case 'failed':
            return <Tag color="error">{status}</Tag>
        case 'running':
            return <Tag color="green">{status}</Tag>
        case 'deploy_not_found':
            return <Tag color="default">Deploy Not Found</Tag>
        default:
            return status ? <Tag color="default">{status}</Tag> : <Tag color="default">Unknown</Tag>
    }
}
