import { Tag } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { capitalizeFirstLetter } from '../../utilities/tools'

export const OrderStatus = ({ status }: { status: string | null }) => {
    switch (status?.toLowerCase()) {
        case 'subscribed':
            return (
                <Tag icon={<CheckCircleOutlined />} color="green">
                    {capitalizeFirstLetter(status)}
                </Tag>
            )
        case 'requested':
            return (
                <Tag icon={<ExclamationCircleOutlined />} color="orange">
                    {capitalizeFirstLetter(status)}
                </Tag>
            )
        case 'unsubscribed':
            return (
                <Tag icon={<CloseCircleOutlined />} color="default">
                    {capitalizeFirstLetter(status)}
                </Tag>
            )
        default:
            return status ? (
                <Tag color="default">{capitalizeFirstLetter(status)}</Tag>
            ) : (
                <Tag color="default">Unknown</Tag>
            )
    }
}
