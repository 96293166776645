// import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'

const { Text } = Typography

type Props = {
    query: any
    errorMessage: string

    link: string
    itemText: string | undefined
    isActive: boolean
}

const InfoItem = ({ query, errorMessage, link, itemText, isActive }: Props) => {
    // item link

    let itemLink = <>{itemText}</> // text without link
    if (isActive) {
        if (link) {
            itemLink = (
                // text with link
                <Link to={link} className="!underline">
                    {itemText}
                </Link>
            )
        }
    } else {
        // invalid text
        itemLink = <Text type="secondary">{itemText} (inactive)</Text>
    }

    return (
        <div>
            {/* loading */}
            {query.isLoading && <Text type="secondary">Loading...</Text>}

            {/* error */}
            {query.isError && (
                <div className="text-xs text-red-500">
                    {/* <span className="font-semibold ">Error {(query.error as FetchBaseQueryError).status}</span>{' '} */}
                    {errorMessage}
                </div>
            )}

            {/* success */}
            {query.isSuccess && itemLink}
        </div>
    )
}

export default InfoItem
