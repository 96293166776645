// Breadcrumbs created with the `handle` prop on the Route elements.

import { Link } from 'react-router-dom'
import { StarOutlined, UserOutlined, BarcodeOutlined, CloudServerOutlined, AppstoreOutlined } from '@ant-design/icons'

export function CrumbDashboard() {
    return (
        <Link to="/dashboard">
            <AppstoreOutlined /> Dashboard
        </Link>
    )
}

export function CrumbOffers() {
    return (
        <Link to="/offers">
            <StarOutlined /> Offers
        </Link>
    )
}

export function crumbOffersAdd() {
    return (
        <Link to="/offers/add">
            <i>New Offer</i>
        </Link>
    )
}

export function crumbOffersOfferId(params: any) {
    return (
        <Link to={`/offers/${params.id}`}>
            {/* <span className="font-semibold">Offer</span> {params.id} */}
            <span className="font-semibold">Offer</span>
        </Link>
    )
}

export function crumbOffersEditOfferId(params: any) {
    return (
        <Link to={`/offers/edit/${params.id}`}>
            <span className="font-semibold">Offer</span> {params.id} <i>(editing)</i>
        </Link>
    )
}

export function crumbOrders() {
    return (
        <Link to="/orders">
            <BarcodeOutlined /> Orders
        </Link>
    )
}

export function crumbOrdersOrderId(params: any) {
    return (
        <Link to={`/orders/${params.orderId}`}>
            {/* <span className="font-semibold">Order</span> {params.orderId} */}
            <span className="font-semibold">Order</span>
        </Link>
    )
}

export function crumbKubeark() {
    return (
        <Link to="/kubeark">
            <CloudServerOutlined /> Kubeark
        </Link>
    )
}

export function crumbKubearkId(params: any) {
    return (
        <Link to={`/kubeark/${params.id}`}>
            <span className="font-semibold">Kubeark</span>
        </Link>
    )
}

export function crumbKubearkAdd() {
    return (
        <Link to={`/kubeark/add`}>
            <i>New Instance</i>
        </Link>
    )
}

export function crumbKubearkEditId(params: any) {
    return (
        <Link to={`/kubeark/edit/${params.id}`}>
            <span className="font-semibold">Kubeark</span> <i>(editing)</i>
        </Link>
    )
}

export function crumbUsers() {
    return (
        <Link to="/users">
            <UserOutlined /> Users
        </Link>
    )
}
