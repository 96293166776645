import { IOffer } from '../../../core-types'
import { getClusterFromClusters, getTemplateFromTemplates } from '../../../utilities/kubeark'
import useItemActiveState from './useItemActiveState'

export type OfferItemsActiveStates = {
    isKInstanceActive: boolean
    isKTemplateActive: boolean
    isKTemplateVersionActive: boolean
    isKClusterActive: boolean
}

/**
 * Group of `useItemActiveState()` hooks.
 * @param currentOffer
 * @param queries
 * @returns { isKInstanceActive, isKTemplateActive, isKTemplateVersionActive, isKClusterActive }
 */
const useItemActiveStates = (currentOffer: IOffer | undefined, queries: any): OfferItemsActiveStates => {
    // Items active state

    // K instance is always active
    const isKInstanceActive = useItemActiveState(queries.queryKInstances.isSuccess, true)

    // K template active state
    const isKTemplateActive = useItemActiveState(
        queries.mutationKTemplates.isSuccess,
        queries.mutationKTemplates.isSuccess &&
            getTemplateFromTemplates(currentOffer?.template_id!, queries.mutationKTemplates?.data?.templates)?.active
    )

    const isKTemplateVersionActive = useItemActiveState(
        queries.mutationKTemplateVersions.isSuccess,
        queries.mutationKTemplateVersions.isSuccess &&
            getTemplateFromTemplates(
                currentOffer?.template_version_id,
                queries.mutationKTemplateVersions?.data?.templates
            )?.active
    )

    // K cluster active
    const isKClusterActive = useItemActiveState(
        queries.queryKClusters.isSuccess,
        queries.queryKClusters.isSuccess &&
            getClusterFromClusters(currentOffer?.cluster_id, queries.queryKClusters?.data)?.active
    )

    return {
        isKInstanceActive,
        isKTemplateActive,
        isKTemplateVersionActive,
        isKClusterActive,
    }
}

export default useItemActiveStates
