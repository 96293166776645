import React from 'react'
import { Divider, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useGetOrdersQuery } from '../api/apiSlice'
import { getOrderFromOrders } from '../../utilities/orders'
import { OrderDetails } from './orderDetails/orderDetails'
import { Link, useParams } from 'react-router-dom'
import '../../theme/components/view.css'
import { IOrder } from '../../core-types'

const { Title, Text } = Typography

export const ViewOrderDetails: React.FC = () => {
    // HOOKS

    const { orderId } = useParams()

    if (!orderId) throw new Error()

    // get all the orders (they are already cached)
    const { data: orders, isLoading, isSuccess, isError, error } = useGetOrdersQuery({})

    // JSX

    return (
        <div className="w-full view-body">
            {/*  header */}
            <header className="view-header">
                <div className="left-side">
                    <div className="title-container">
                        <Link to={'/orders'}>
                            <div className="nav-icon">
                                <div className="icon-container">
                                    <ArrowLeftOutlined />
                                </div>
                            </div>
                        </Link>
                        <Title level={2} className="!my-0">
                            Order
                        </Title>
                    </div>
                    <Text type="secondary">Order details.</Text>
                </div>
                <div className="right-side"></div>
            </header>

            <Divider />

            {isLoading && <p>Loadig...</p>}
            {isSuccess && (
                <div>
                    <OrderDetails order={getOrderFromOrders(Number(orderId), orders) as IOrder} />
                </div>
            )}

            {/* FIXME Get the error message for this RTKQ error type */}
            {/* error */}
            {isError && (
                <p className="text-red-400">
                    Error Cannot fetch order details.
                    <br /> {error.toString()}
                </p>
            )}
        </div>
    )
}
