import { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Layout, Menu } from 'antd'
import { StarOutlined, BarcodeOutlined, CloudServerOutlined, AppstoreOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'

import { getPathComponents } from '../../../utilities/tools'
import logoApp from '../../../img/logo-app.svg'

const { Sider } = Layout

const items: MenuProps['items'] = [
    {
        key: 'dashboard',
        icon: <AppstoreOutlined />,
        label: 'Dashboard',
    },
    {
        key: 'offers',
        icon: <StarOutlined />,
        label: 'Offers',
    },
    {
        key: 'orders',
        icon: <BarcodeOutlined />,
        label: 'Orders',
    },
    {
        key: 'kubeark',
        icon: <CloudServerOutlined />,
        label: 'Kubeark',
    },
]

const Sidebar = () => {
    // HOOKS

    // router
    const location = useLocation()
    const navigate = useNavigate()

    const [currentSelectedPage, setCurrentSelectedPage] = useState('')

    // keep the current navigation path for each page; this paths will be used in the sidebar items.
    const [paths, setPaths] = useState(
        new Map([
            ['dashboard', '/dashboard'],
            ['offers', '/offers'],
            ['orders', '/orders'],
            ['kubeark', '/kubeark'],
        ])
    )

    // If the location path starts with 'offers', etc, than update the corresponding sidebar link to that location path.
    // `page` will be "offers", "orders", etc.
    useEffect(() => {
        const page = getPathComponents(location.pathname)[0]
        setPaths(paths.set(page, `${location.pathname}${location.search}`))
        setCurrentSelectedPage(page)
    }, [location, paths])

    // HANDLERS

    // when you select a sidebar item, navigate to that current page path
    const onSidebarItemSelect = useCallback(
        ({ key }: any) => {
            const fullPath: string | undefined = paths.get(key)
            fullPath && navigate(fullPath)
        },
        [paths, navigate]
    )

    // JSX

    return (
        <Sider
            className="!fixed top-0 bottom-0 left-0 h-screen overflow-auto z-20"
            // style={{ backgroundColor: token.colorBgContainer }}
        >
            <Link className="inline-block mx-4 my-4 rounded-full" to={'/dashboard'}>
                <img src={logoApp} className="h-8" alt="App Logo" />
            </Link>

            <Menu
                theme="dark"
                mode="inline"
                selectedKeys={[currentSelectedPage]}
                items={items}
                onSelect={onSidebarItemSelect}
            />
        </Sider>
    )
}

export default Sidebar
