import React from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

type Props = {
    kubearkInstance: any
}

const InstanceDetails: React.FC<Props> = ({ kubearkInstance }) => {
    // HOOKS

    // JSX

    return (
        true && (
            <div className="flex flex-row">
                {true && (
                    <section className="flex px-4 py-4 pb-8 rounded-lg shadow-md bg-slate-50">
                        {/* column one */}
                        <div className="flex-grow mr-8 min-w-[320px] max-w-[420px] ">
                            <div className="text-gray-400 ">
                                {/* updated, created */}
                                <div className="">
                                    {/* updated at */}
                                    <div className="font-semibold text-gray-500">{kubearkInstance.name}</div>
                                    <div className="my-0 text-gray-400">
                                        {
                                            <a
                                                className="!text-gray-500 hover:!text-gray-800 text-sm !underline"
                                                href={kubearkInstance.kubeark_url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Kubeark URL
                                            </a>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* Access Token */}
                            <div className="mt-8">
                                <div className="leading-none text-gray-400 ">Access Token</div>
                                <div className="my-0 font-mono text-gray-500">
                                    {kubearkInstance.kubeark_access_token}
                                </div>
                            </div>
                        </div>
                        <Link to={`/kubeark/edit/${kubearkInstance.id}`}>
                            <Button>Edit Instance</Button>
                        </Link>
                    </section>
                )}
            </div>
        )
    )
}

export default InstanceDetails
