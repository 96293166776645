import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDeleteOfferMutation } from '../../api/apiSlice'
import { showDeleteConfirmModal } from '../components/modalDeleteConfirm'

const useMainHeaderOfferDetails = (id: number) => {
    // HOOKS

    // mutations
    const [deleteOffer, deleteOfferMutation] = useDeleteOfferMutation()

    // react router
    const navigate = useNavigate()

    // on successfull offer delete, navigate to `/offers`
    useEffect(() => {
        deleteOfferMutation.isSuccess && navigate('/offers')
    }, [deleteOfferMutation.isSuccess, navigate])

    // HANDLERS

    const onDeleteOfferClick = async () => {
        if (id) {
            showDeleteConfirmModal(id, deleteOffer)
        }
    }

    return {
        // state
        isLoadingDelete: deleteOfferMutation.isLoading,
        // handlers
        onDeleteOfferClick,
    }
}

export default useMainHeaderOfferDetails
