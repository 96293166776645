import React from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input, Typography } from 'antd'

import './pageSignUp.css'
import { Link } from 'react-router-dom'

const { Text } = Typography

export const PageSignUp: React.FC = () => {
    const onFinish = (values: any) => {}

    return (
        <div className="p-12 -mt-24 text-center rounded-lg shadow-xl signup-page w-80">
            {/* <img src={logoApp} alt="Logo" className="h-12" /> */}
            <Text type="secondary">Create a new Kubeark account.</Text>
            <Form
                name="normal_login"
                className="text-left signup-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                style={{ marginTop: '28px' }}
            >
                <Form.Item name="username" rules={[{ required: true, message: 'Please input a Username!' }]}>
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                    className=""
                    style={{ marginTop: '32px' }}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password (8+ characters)"
                    />
                </Form.Item>

                <Form.Item style={{ marginTop: '48px' }}>
                    <Button type="primary" htmlType="submit" className="block w-full login-form-button">
                        Create Account
                    </Button>
                </Form.Item>

                <Form.Item>
                    <div className="mt-2">
                        Already have an account? <Link to={`/login`}>Log in</Link>.
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}
