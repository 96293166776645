import useItemNames from '../hooks/useItemNames'
import useItemLinks from '../hooks/useItemLinks'
import useItemActiveStates from './useItemActiveStates'
import { IOffer } from '../../../core-types'

/**
 * Group offer info used inside TabOverview.
 * @param currentOffer
 * @returns {itemNames, itemLinks,itemActiveStates}
 */
const useOfferInfo = (currentOffer: IOffer, queriesOfferInfo: any) => {
    // offer items links, names and active states
    const itemNames = useItemNames(currentOffer, queriesOfferInfo)
    const itemLinks = useItemLinks(currentOffer, queriesOfferInfo)
    const itemActiveStates = useItemActiveStates(currentOffer, queriesOfferInfo)

    return {
        itemNames,
        itemLinks,
        itemActiveStates,
    }
}

export default useOfferInfo
