// Global config

// extract application version from package.json
import packageInfo from '../package.json'

const config = {
    // App
    appName: 'Kubeark Transactable for Azure',
    version: packageInfo.version,

    // KAT API (moved it to .env and again here :) )
    // katApiUrl: 'https://ka-test-transactable.westeurope.cloudapp.azure.com',
    // katApiUrl: 'http://kubeark-transactable-be-service.kubeark-transactable.svc:80/api/transact/v1',

    // company
    companyName: 'Kubeark',
    currentYear: 2023,
}

export default config
