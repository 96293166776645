import { configureStore } from '@reduxjs/toolkit'
// import layoutReducer from '../features/slices/layoutSlice'
import { apiSlice } from '../features/api/apiSlice'
// import { apiSliceTest } from '../features/api/apiSliceAuth'
import authReducer from '../features/auth/authSlice'

export const store = configureStore({
    reducer: {
        // layout: layoutReducer,
        auth: authReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
        // WIP Just for test (using multiple baseUrls)
        // [apiSliceTest.reducerPath]: apiSliceTest.reducer,
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
    // TEMP
    middleware: (getDefaultMiddleware) =>
        // getDefaultMiddleware().concat(apiSlice.middleware).concat(apiSliceTest.middleware),
        getDefaultMiddleware().concat(apiSlice.middleware),

    // TODO Switch off in production
    devTools: true,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
// export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
