import React, { useCallback, useState } from 'react'
import { Button, Divider, Space, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ListKubeark from './listKubeark/listKubeark'
import { Link } from 'react-router-dom'
import '../../theme/components/view.css'
import { useGetKubearkInstancesQuery } from '../api/apiSlice'
import Toolbar from '../components/Toolbar'

const { Title, Text } = Typography

export const ViewKubearkList: React.FC = () => {
    // HOOKS

    const kinstancesQuery = useGetKubearkInstancesQuery({})
    const [searchedText, setSearchedText] = useState('')

    const onSearchInputChange = useCallback(
        (ev: any) => {
            setSearchedText(ev.target.value)
        },
        [setSearchedText]
    )

    // HOOKS
    return (
        <div className="w-full view-body">
            {/* header */}
            <header className="view-header">
                <div className="left-side">
                    <div className="title-container">
                        <Title level={2} className="!my-0">
                            Kubeark
                        </Title>
                    </div>
                    <Text type="secondary">
                        {kinstancesQuery.isSuccess && `${kinstancesQuery.data.length} Kubeark instances`}
                    </Text>
                </div>
                <div className="right-side">
                    <Space size="large">
                        {/* new instance button */}
                        <Link to="add">
                            <Button type="primary" icon={<PlusOutlined />}>
                                New Instance
                            </Button>
                        </Link>
                    </Space>
                </div>
            </header>

            <Divider />

            {/*  Kubeark table */}

            <Toolbar onSearchInputChange={onSearchInputChange} />
            <ListKubeark kinstancesQuery={kinstancesQuery} searchedText={searchedText} />
        </div>
    )
}
