import { useEffect, useState } from 'react'

const useItemActiveState = (isSuccessQuery: boolean, activeVal: boolean | undefined) => {
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (isSuccessQuery) {
            if (activeVal) {
                setIsActive(true)
            } else {
                setIsActive(false)
            }
        }
    }, [activeVal, isSuccessQuery])

    return isActive
}

export default useItemActiveState
