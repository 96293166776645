import { SelectOption } from '../features/groupOffers/types'

/**
 * Make a date into a pretty string.
 *
 * @param date
 * @returns
 */
export const prettyDate = (date: Date): string =>
    date.toLocaleString('default', {
        month: 'long',
        year: 'numeric',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    })

/**
 * Get an array of path elements from a path string.
 *
 * @param path a URL location path. Ex: '/offers/add'
 * @returns Array of path elements (what is sepparated by /). Ex: ["offers", "add"]
 *
 * The function also makes sure to filter out the empty strings returned from split()
 * because of the starting slash '/' and a possible ending slash '/' at the end.
 *
 * Example: For "/offers/add" returns ["offers", "add"]
 *
 */
export const getPathComponents = (path: string): string[] => path.split('/').filter((item: string) => item !== '')

/**
 * Get an option from a list of options used in a <Select>.
 *
 * @param optionValue A number value used as an Id inside the <Select> optoins.
 * @param options Array of SelectOption
 * @returns A SelectOption
 */
export const getOptionFromSelectOptions = (optionValue: number, options: SelectOption[]): SelectOption | undefined => {
    return options.find((option: SelectOption) => option.value === optionValue)
}

export const capitalizeFirstLetter = (str: string) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`
