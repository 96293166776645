import { useMemo } from 'react'
import {
    getClusterFromClusters,
    getKubearkInstanceFromInstances,
    getTemplateFromTemplates,
} from '../../../utilities/kubeark'
import { IOffer } from '../../../core-types'

type QueriesType = {
    queryKInstances: any
    mutationKTemplates: any
    mutationKTemplateVersions: any
    queryKClusters: any
}

const useItemNamesAndLinks = (currentOffer: IOffer | undefined, queries: QueriesType) => {
    // memo - K instance name
    const kInstanceName = useMemo(
        () => getKubearkInstanceFromInstances(currentOffer?.kubeark_id!, queries.queryKInstances?.data)?.name,
        [currentOffer?.kubeark_id, queries.queryKInstances?.data]
    )

    // memo - K template name
    const kTemplateName = useMemo(
        () => getTemplateFromTemplates(currentOffer?.template_id!, queries.mutationKTemplates?.data?.templates)?.name,
        [currentOffer?.template_id, queries.mutationKTemplates?.data?.templates]
    )

    // memo - K template version name
    const kTemplateVersionName = useMemo(
        () =>
            getTemplateFromTemplates(
                currentOffer?.template_version_id,
                queries.mutationKTemplateVersions?.data?.templates
            )?.name,
        [currentOffer?.template_version_id, queries.mutationKTemplateVersions?.data?.templates]
    )

    // memo - K cluster name
    const kClusterName = useMemo(() => {
        return getClusterFromClusters(currentOffer?.cluster_id, queries.queryKClusters.data)?.name
    }, [currentOffer?.cluster_id, queries.queryKClusters.data])

    return {
        kInstanceName,
        kTemplateName,
        kTemplateVersionName,
        kClusterName,
    }
}

export default useItemNamesAndLinks
