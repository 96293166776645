import React, { useCallback, useState } from 'react'
import { Divider, Typography } from 'antd'
import ListOrders from './listOrders/listOrders'
import '../../theme/components/view.css'
import { useGetOrdersQuery } from '../api/apiSlice'
import Toolbar from '../components/Toolbar'

const { Title, Text } = Typography

export const ViewOrdersList: React.FC = () => {
    // HOOKS

    const ordersQuery = useGetOrdersQuery({})
    const [searchedText, setSearchedText] = useState('')

    const onSearchInputChange = useCallback(
        (ev: any) => {
            setSearchedText(ev.target.value)
        },
        [setSearchedText]
    )

    // JSX

    return (
        <div className="w-full view-body">
            {/* header */}
            <header className="view-header">
                <div className="left-side">
                    <div className="title-container">
                        <Title level={2} className="!my-0 view-title">
                            Orders
                        </Title>
                    </div>
                    <Text type="secondary">{ordersQuery.isSuccess && `${ordersQuery.data.length} orders`}</Text>
                </div>
                <div className="right-side"></div>
            </header>

            <Divider />

            {/*  Orders table */}

            <Toolbar onSearchInputChange={onSearchInputChange} />
            <ListOrders ordersQuery={ordersQuery} searchedText={searchedText} />
        </div>
    )
}
