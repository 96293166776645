// A page to test a protected route

import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectCurrentToken, selectCurrentUser } from './authSlice'

type Props = {}

const TestAuthPage = (props: Props) => {
    const user = useSelector(selectCurrentUser)
    const token = useSelector(selectCurrentToken)

    return (
        <div>
            {user && <p>{`Hello, ${user}!`}</p>}
            {token && <p>{`Token is ${token.slice(0, 9)}...`}</p>}
            <p>
                <Link to="/userslist">Go to /userslist</Link>
                <br />
                <Link to="/">Go to Log In</Link>
            </p>
        </div>
    )
}

export default TestAuthPage
