import { Layout, Button, Space, theme, Typography, Avatar } from 'antd'
import Breadcrumbs from '../breadcrumbs/breadcrumbs'
import { selectCurrentUser } from '../../auth/authSlice'
import { useSelector } from 'react-redux'

// LATER Fix this
import './mainMenu.css'
import { Link } from 'react-router-dom'
import useLogout from '../../groupAuth/hooks/useLogout'

const { Header } = Layout
const { Text } = Typography
const { useToken } = theme

type Props = {}

const MainMenu = (props: Props) => {
    const user = useSelector(selectCurrentUser)

    const { token } = useToken()

    // const themeContext = useContext(ThemeContext)

    // NOTE AUTH
    const { handleLogout } = useLogout()

    // change theme
    // const onChangeTheme = useCallback(() => {
    //     if (themeContext?.theme === 'kubeDarkTheme') {
    //         themeContext?.setTheme('kubeLightTheme')
    //     } else {
    //         themeContext?.setTheme('kubeDarkTheme')
    //     }
    // }, [themeContext])

    // JSX
    return (
        <Header className="shadow-sm main-header" style={{ backgroundColor: token.colorBgContainer }}>
            <div className="content">
                {/* Left side */}
                <div className="side-left">
                    <Space wrap>
                        <Breadcrumbs />
                    </Space>
                </div>

                {/* Right side */}
                <div className="side-right">
                    <Space wrap>
                        {/* Log out */}
                        <Space size="middle">
                            {/* <Button shape="circle" type="text" onClick={onChangeTheme}>
                                <span className="w-4">{svgDarkMode}</span>
                            </Button> */}

                            <Button onClick={handleLogout} type="link">
                                <Text type="secondary" className="underline">
                                    Sign Out
                                </Text>
                            </Button>

                            {/* User avatar */}
                            <Link to="/users">
                                <Avatar
                                    className="mr-6 text-lg leading-9 !align-middle bg-yellow-400 hover:bg-yellow-300 shadow-sm"
                                    size="large"
                                >
                                    {user?.charAt(0).toUpperCase()}
                                </Avatar>
                            </Link>
                        </Space>
                    </Space>
                </div>
            </div>
        </Header>
    )
}

export default MainMenu
