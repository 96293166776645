import { useCallback } from 'react'
// redux
import { useDispatch } from 'react-redux'
import { logOut } from '../../auth/authSlice'

const useLogout = () => {
    // HOOKS

    const dispatch = useDispatch()

    // HANDLERS

    const handleLogout = useCallback(() => {
        dispatch(logOut({ user: undefined, access_token: null, refresh_token: null }))

        // clear the local storage
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
    }, [dispatch])

    return {
        handleLogout,
    }
}

export default useLogout
