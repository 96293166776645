import { Form } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { IOffer } from '../../../core-types'
import useItemActiveStates from './useItemActiveStates'
import { convertFormOfferValuesToOfferData } from '../../../utilities/offers'
import useOfferQueries from './useOfferQueries'

export type FormOfferValues = {
    offer_id: string
    plan_id: string
    kubeark_id: number | undefined | null
    template_id: number | undefined | null
    template_version_id: number | undefined | null
    cluster_id: number | undefined | null
}

const useFormOffer = (
    offer: IOffer | undefined,
    setIsDirty: (isDirty: boolean) => void,
    offerUpdateMethod: (offerData: any) => Promise<any>, // Ex: `addOffer`, `updateOffer` mutations
    updateIsOpenParent: (isOpen: boolean) => void
) => {
    const [currentKInstanceId, setCurrentKInstanceId] = useState<number | undefined>(undefined) // current kubeark id
    const [currentKTemplateId, setCurrentKTemplateId] = useState<number | undefined>(undefined) // current template id

    // LATER I want it like this? Too much?
    const queriesOfferInfo = useOfferQueries(offer?.kubeark_id, offer?.template_id)
    const itemActiveStates = useItemActiveStates(offer, queriesOfferInfo)

    const [form] = Form.useForm()

    const initialValues = useMemo(() => {
        const formValues = {
            offer_id: offer?.offer_id ?? '',
            plan_id: offer?.plan_id ?? '',

            kubeark_id: offer && itemActiveStates?.isKInstanceActive ? offer?.kubeark_id : null,
            template_id: offer && itemActiveStates?.isKTemplateActive ? offer?.template_id : null,
            template_version_id:
                offer && itemActiveStates?.isKTemplateVersionActive ? offer?.template_version_id : null,
            cluster_id: offer && itemActiveStates?.isKClusterActive ? offer?.cluster_id : null,
        }

        return formValues
    }, [
        itemActiveStates?.isKClusterActive,
        itemActiveStates?.isKInstanceActive,
        itemActiveStates?.isKTemplateActive,
        itemActiveStates?.isKTemplateVersionActive,
        offer,
    ])

    // load offer values
    useEffect(() => {
        if (offer) {
            // set the form fields to offer values
            form.setFieldsValue(initialValues)

            // we need to set this to trigger the fetching of templates, versions, clusters
            setCurrentKInstanceId(offer.kubeark_id)
            setCurrentKTemplateId(offer.template_id)
        }
    }, [form, initialValues, offer])

    // HANDLERS

    // Change k instance, changes the current k instance; this will set the skip toggle for templates and clusters to `false` inside `useOfferQueries(...)`
    const onChangeSelectKInstance = useCallback(
        (value: number) => {
            setCurrentKInstanceId(value)
            setCurrentKTemplateId(undefined)

            // when I change the K instance, clear the selected template, version, cluster
            form.setFieldValue('template_id', null)
            form.setFieldValue('template_version_id', null)
            form.setFieldValue('cluster_id', null)
        },
        [form]
    )

    // Change the current K template.
    // Set the template version id to null when you change the main template, so by default nothing is selected.
    const onChangeSelectKTemplate = useCallback(
        (value: number) => {
            setCurrentKTemplateId(value)
            form.setFieldValue('template_version_id', null)
        },
        [form]
    )

    const handleFormChange = useCallback(() => {
        setIsDirty(form.isFieldsTouched())
    }, [form, setIsDirty])

    // form submit
    const onFinish = useCallback(
        async (data: FormOfferValues) => {
            // prepare the data to submit
            const updatedOfferData = convertFormOfferValuesToOfferData({ ...data })
            // updatedOfferData.extra_args = null
            updatedOfferData.id = offer?.id

            try {
                await offerUpdateMethod(updatedOfferData) // update the offer using the specified mutation
            } catch (error) {
                throw new Error(`Cannot update offer.`)
            }

            // close the parent window (the drawer)
            // updateIsOpenParent(false)
        },
        [offer?.id, offerUpdateMethod]
    )

    return {
        // form
        form,
        initialValues,
        handleFormChange,
        onFinish,
        // state
        currentKInstanceId,
        currentKTemplateId,
        // handlers
        onChangeSelectKInstance,
        onChangeSelectKTemplate,
    }
}

export default useFormOffer
