// router
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'

// layouts
import RootLayout from './features/groupLayout/rootLayout/rootLayout'
import LayoutLogin from './features/groupLayout/layoutLogin/layoutLogin'
import LayoutDefault from './features/groupLayout/layoutDefault/layoutDefault'
import { PageContainer } from './features/groupLayout/pageContainer/pageContainer'

// auth pages
import RequireAuth from './features/auth/requireAuth'
import { PageLogin } from './features/groupAuth/pageLogin/pageLogin'
import { PageSignUp } from './features/groupAuth/pageSignUp/pageSignUp'
import { PageRecoverPassword } from './features/groupAuth/pageRecoverPassword/pageRecoverPassword'
// test
import TestAuthPage from './features/auth/testAuthPage'

// pages

// home page
import { PageHome } from './features/groupLayout/pageHome'
// offers
import { ViewOfferDetails } from './features/groupOffers/components/ViewOfferDetails'
import { ViewOffersList } from './features/groupOffers/components/ViewOffersList'

import OffersError from './features/groupOffers/components/OffersErrorX'
// orders
import PageNotFound from './features/pageNotFound/pageNotFound'
import OrdersError from './features/groupOrders/ordersError'
import { ViewOrdersList } from './features/groupOrders/viewOrdersList'
import { ViewOrderDetails } from './features/groupOrders/viewOrderDetails'
// kubeark
import KubearkError from './features/groupKubeark/kubearkError'
import { ViewKubearkList } from './features/groupKubeark/viewKubearkList'
import { ViewKubearkDetails } from './features/groupKubeark/viewKubearkDetails'
// users
import { ViewUsersList } from './features/groupUsers/viewUsersList/viewUsersList'

import {
    CrumbDashboard,
    crumbKubeark,
    crumbKubearkAdd,
    crumbKubearkEditId,
    crumbKubearkId,
    CrumbOffers,
    crumbOffersOfferId,
    crumbOrders,
    crumbOrdersOrderId,
    crumbUsers,
} from './features/router/crumbs'
import DashboardError from './features/groupDashboard/viewDashboard/dashboardError'
import { ViewDashboard } from './features/groupDashboard/viewDashboard/viewDashboard'
import { ViewKubearkDetailsAdd } from './features/groupKubeark/viewKubearkDetailsAdd'
import { ViewKubearkDetailsEdit } from './features/groupKubeark/viewKubearkDetailsEdit'
import RootContent from './features/groupLayout/rootLayout/RootContent'

function App() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<RootLayout />}>
                <Route path="/" element={<RootContent />}>
                    {/* public routes */}

                    {/* homepage (redirects now) */}
                    <Route index element={<PageHome />} />

                    {/* health link (for deployment) */}
                    <Route path="/health" element={<p>This is one healthy app.</p>} />

                    {/* auth pages (public) */}
                    <Route path="/" element={<LayoutLogin />}>
                        <Route path="login" element={<PageLogin />} />
                        <Route path="signup" element={<PageSignUp />} />
                        <Route path="recover" element={<PageRecoverPassword />} />
                    </Route>

                    {/* protected routes */}
                    <Route path="/" element={<RequireAuth />}>
                        {/* main pages */}
                        <Route path="/" element={<LayoutDefault />}>
                            {/* dashboard */}
                            <Route
                                path="dashboard"
                                element={<PageContainer />}
                                errorElement={<DashboardError />}
                                handle={{
                                    crumb: CrumbDashboard,
                                }}
                            >
                                <Route index element={<ViewDashboard />} />
                            </Route>

                            {/* offers */}
                            <Route
                                path="offers"
                                element={<PageContainer />}
                                errorElement={<OffersError />}
                                handle={{
                                    crumb: CrumbOffers,
                                }}
                            >
                                <Route index element={<ViewOffersList />} />
                                <Route
                                    path=":id"
                                    element={<ViewOfferDetails />}
                                    handle={{
                                        crumb: crumbOffersOfferId,
                                    }}
                                />
                            </Route>

                            {/* orders */}
                            <Route
                                path="orders"
                                element={<PageContainer />}
                                errorElement={<OrdersError />}
                                handle={{
                                    crumb: crumbOrders,
                                }}
                            >
                                <Route index element={<ViewOrdersList />} />
                                <Route
                                    path=":orderId"
                                    element={<ViewOrderDetails />}
                                    handle={{
                                        crumb: crumbOrdersOrderId,
                                    }}
                                />
                            </Route>

                            {/* kubeark */}
                            <Route
                                path="kubeark"
                                element={<PageContainer />}
                                errorElement={<KubearkError />}
                                handle={{
                                    crumb: crumbKubeark,
                                }}
                            >
                                <Route index element={<ViewKubearkList />} />
                                <Route
                                    path=":id"
                                    element={<ViewKubearkDetails />}
                                    handle={{
                                        crumb: crumbKubearkId,
                                    }}
                                />
                                <Route
                                    path="add"
                                    element={<ViewKubearkDetailsAdd />}
                                    handle={{
                                        crumb: crumbKubearkAdd,
                                    }}
                                />
                                <Route
                                    path="edit/:id"
                                    element={<ViewKubearkDetailsEdit />}
                                    handle={{
                                        crumb: crumbKubearkEditId,
                                    }}
                                />
                            </Route>

                            {/* users */}
                            <Route
                                path="users"
                                element={<PageContainer />}
                                // errorElement={<KubearkError />}
                                handle={{
                                    crumb: crumbUsers,
                                }}
                            >
                                <Route index element={<ViewUsersList />} />
                            </Route>

                            {/* testing */}
                            <Route path="test" element={<TestAuthPage />} />
                            {/* <Route path="userslist" element={<UsersList />} /> */}
                        </Route>
                    </Route>

                    {/* Error page: catch-all route for non-existing pages. */}
                    <Route path="*" element={<PageNotFound />} />
                </Route>
            </Route>
        )
    )

    return <RouterProvider router={router} />
}

export default App
