import React, { useCallback, useEffect, useState } from 'react'
import { Divider, Spin, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import ListOffers from './ListOffersX'
import { useAddNewOfferMutation, useGetOffersQuery } from '../../api/apiSlice'
import DrawerOfferOverview from './DrawerOfferOverview'
import { useDrawerOfferOverview } from '../hooks/useDrawerOfferOverview'
import FormOffer from './FormOffer'
import MainHeaderOffersList from './MainHeaderOffersList'
import Toolbar from '../../components/Toolbar'
import useMainHeaderOffersList from '../hooks/useMainHeaderOffersList'

export const ViewOffersList: React.FC = () => {
    // HOOKS

    const [searchedText, setSearchedText] = useState('')

    // offers query
    const offersQuery = useGetOffersQuery({})

    // add offer mutation
    const [
        addOffer,
        {
            isLoading: isLoadingAddNewOffer,
            isSuccess: isSuccessAddNewOffer,
            isError: isErrorAddNewOffer,
            error: errorAddNewOffer,
        },
    ] = useAddNewOfferMutation()

    // new offer drawer
    const {
        setOpen: setIsOpenDrawerOfferOverview,
        isDirty: isSubmitDirty,
        setIsDirty: setIsSubmitDirty,
        open: isOpenDrawerOfferOverview,
        onClose: onCloseDrawerOfferOverview,
    } = useDrawerOfferOverview()

    // callback to update offer drawer `isOpen` state
    const updateIsOpenDrawerOfferOverview = useCallback(
        (isOpen: boolean): void => {
            setIsOpenDrawerOfferOverview(isOpen)
        },
        [setIsOpenDrawerOfferOverview]
    )

    // callback to add the offer
    const offerAddMethod = useCallback(
        async (newOfferData: any) => {
            await addOffer(newOfferData)
        },
        [addOffer]
    )

    // create offer success
    useEffect(() => {
        if (isSuccessAddNewOffer) {
            message.success('Offer created.')
            setIsOpenDrawerOfferOverview(false)
        }
    }, [isSuccessAddNewOffer, setIsOpenDrawerOfferOverview])

    // create offer error
    useEffect(() => {
        if (isErrorAddNewOffer && errorAddNewOffer) {
            message.error({
                content: `Cannot create offer: ${errorAddNewOffer.data.error}`,
                duration: 5,
            })
        }
    }, [isErrorAddNewOffer, errorAddNewOffer])

    // main header
    const { onNewOfferClick } = useMainHeaderOffersList(updateIsOpenDrawerOfferOverview)

    // HANDLERS

    // on close drawer also reset the form
    const onCloseDrawer = useCallback(() => {
        onCloseDrawerOfferOverview()
        // resetFormOffer()
    }, [onCloseDrawerOfferOverview])

    const onSearchInputChange = useCallback(
        (ev: any) => {
            setSearchedText(ev.target.value)
        },
        [setSearchedText]
    )

    // JSX

    return (
        <>
            {/* Drawer - New Offer */}
            <DrawerOfferOverview
                title="New Offer"
                isOpen={isOpenDrawerOfferOverview}
                isLoading={isLoadingAddNewOffer}
                onClose={onCloseDrawer}
                isDirty={isSubmitDirty}
            >
                <FormOffer
                    offer={undefined}
                    setIsDirty={setIsSubmitDirty}
                    updateIsOpenParent={updateIsOpenDrawerOfferOverview}
                    offerUpdateMethod={offerAddMethod}
                />
            </DrawerOfferOverview>

            {/* Header */}
            <MainHeaderOffersList
                offersCount={offersQuery.isSuccess && offersQuery.data.length}
                onNewOfferClick={onNewOfferClick}
            />

            <Divider />

            {/* Toolbar: search input */}
            <Toolbar onSearchInputChange={onSearchInputChange} />

            {/* Loading (also fetching) offers list */}
            {offersQuery.isLoading && (
                <div className="p-6 text-center">
                    <Spin indicator={<LoadingOutlined className="text-sm" spin />} />
                </div>
            )}

            {/* Offers List */}
            {offersQuery.isSuccess && <ListOffers offersQuery={offersQuery} searchedText={searchedText} />}
        </>
    )
}
