import { Modal } from 'antd'

const { confirm } = Modal

export const showDeleteConfirmModal = (id: number, deleteOffer: any) => {
    confirm({
        centered: true,
        icon: null,
        content: `Are you sure you want to delete this offer?`,
        okText: 'Delete Offer',
        okType: 'danger',
        cancelText: 'No',
        async onOk() {
            await deleteOffer(id)
        },
        onCancel() {},
    })
}
