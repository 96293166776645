import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Select, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { getTemplateFromTemplates } from '../../utilities/kubeark'
import { svgIconApps, svgIconDate, svgIconInfo, svgIconInstance, svgIconTime, svgIconVersions } from '../../svgs'
// import { useGetKubearkInstanceTemplatesQuery } from '../api/apiSlice'

import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { IKInstance, IKTemplate } from '../../core-types'
import { useSearchParams } from 'react-router-dom'
import { getOptionFromSelectOptions } from '../../utilities/tools'
import { useGetKubearkInstanceTemplatesMutation } from '../api/apiSlice'

type Props = {
    kubearkInstance: IKInstance
}

// custom hook
const useMainQueries = (kubearkInstanceId: number) => {
    // get all templates for the K instance (using a mutation - POST with body )
    const [getKInstanceTemplates, mutationKTemplates] = useGetKubearkInstanceTemplatesMutation()

    // get the K templates
    useEffect(() => {
        getKInstanceTemplates({ id: kubearkInstanceId, perPage: 200 })
    }, [getKInstanceTemplates, kubearkInstanceId])

    // filter the active templates
    const activeTemplates: IKTemplate[] = useMemo(() => {
        return (
            mutationKTemplates.isSuccess &&
            mutationKTemplates.data.templates.filter((template: IKTemplate) => template.active)
        )
    }, [mutationKTemplates.isSuccess, mutationKTemplates.data])

    // options for <Select>
    const optionsActiveTemplates = useMemo(() => {
        if (mutationKTemplates.isSuccess) {
            return activeTemplates.map((template: IKTemplate) => ({
                value: template.id,
                label: template.name,
            }))
        } else {
            return []
        }
    }, [activeTemplates, mutationKTemplates.isSuccess])

    return { mutationKTemplates, activeTemplates, optionsActiveTemplates }
}

const Templates: React.FC<Props> = ({ kubearkInstance }) => {
    // HOOKS

    // router
    const [searchParams, setSearchParams] = useSearchParams()

    // current selected template from the Select dropdown or from the `template` search param
    const [currentTemplate, setCurrentTemplate] = useState<IKTemplate | undefined>(undefined)

    // templates query object and the memoized active templates
    const { mutationKTemplates, activeTemplates, optionsActiveTemplates } = useMainQueries(kubearkInstance.id)

    // Set the selected template and the `template` search param if it doesn't exist
    useEffect(() => {
        if (activeTemplates) {
            if (searchParams && searchParams.get('template')) {
                // read the `template` search param and set the selected template to it
                const templateId = searchParams.get('template')
                if (typeof templateId === 'string') {
                    setCurrentTemplate(getTemplateFromTemplates(Number(templateId), activeTemplates))
                }
            } else {
                // if no template is selected, take the first template in the array
                setCurrentTemplate(activeTemplates[0])
                setSearchParams({
                    ...Object.fromEntries([...searchParams]),
                    template: String(activeTemplates[0].id),
                })
            }
        }
    }, [searchParams, activeTemplates, setSearchParams])

    // HANDLERS

    // on select template
    // Set the selected template and update the `template`  search param.
    const handleSelectTemplateChange = useCallback(
        (value: string) => {
            const template = getTemplateFromTemplates(Number(value), activeTemplates) as IKTemplate
            setCurrentTemplate(template)
            setSearchParams({
                ...Object.fromEntries([...searchParams]),
                template: String(template.id),
            })
        },
        [setCurrentTemplate, activeTemplates, searchParams, setSearchParams]
    )

    // JSX

    return (
        <div className="flex-grow ">
            {/* Loading (also fetching) */}
            {mutationKTemplates.isLoading && (
                <div className="mt-2">
                    <Spin indicator={<LoadingOutlined className="text-sm" spin />} />
                </div>
            )}

            {/* success templates */}
            {mutationKTemplates.isSuccess && (
                <div className="flex flex-col">
                    {/* template header */}
                    <header className="flex items-center py-4 grow">
                        {/* side left */}
                        <div className="flex items-center h-full">
                            <div>
                                <div className="leading-none text-gray-400 ">Template</div>
                                {optionsActiveTemplates && currentTemplate && (
                                    <Select
                                        defaultValue={
                                            getOptionFromSelectOptions(currentTemplate.id, optionsActiveTemplates)
                                                ?.label
                                        }
                                        className="w-[280px] mt-1"
                                        onChange={handleSelectTemplateChange}
                                        options={optionsActiveTemplates}
                                    />
                                )}
                            </div>

                            {currentTemplate && (
                                <div className="flex">
                                    {/* kubeark instance */}
                                    <div className="pl-4 ml-8 border-0 border-l border-gray-100 border-solid">
                                        <div className="flex items-center text-gray-400">
                                            <div className="w-4 h-4">{svgIconInstance}</div>
                                            <div className="ml-2 leading-none">Instance</div>
                                        </div>
                                        <div className="mt-1 ml-6">
                                            <a
                                                className="!text-gray-500 hover:!text-gray-800 !underline"
                                                href={kubearkInstance?.kubeark_url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {kubearkInstance?.name}
                                            </a>
                                        </div>
                                    </div>

                                    {/* running apps and versions */}
                                    <div className="pl-4 ml-8 border-0 border-l border-gray-100 border-solid">
                                        <div className="flex items-center text-gray-400">
                                            {svgIconApps}{' '}
                                            <div className="ml-2 leading-none min-w-[100px]">Running Apps</div>
                                            <div className="ml-4 text-gray-500">
                                                {currentTemplate?.total_application_count}
                                            </div>
                                        </div>
                                        <div className="flex items-center text-gray-400">
                                            {svgIconVersions}{' '}
                                            <div className="ml-2 leading-none min-w-[100px]">Versions</div>
                                            <div className="ml-4 text-gray-500">
                                                {currentTemplate?.total_versions_count}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* side right */}
                        {currentTemplate && (
                            <div className="flex items-center justify-end flex-grow">
                                {/* public badge */}
                                {currentTemplate.public && (
                                    <span className="inline-flex items-center rounded-full bg-green-50 px-3 py-0.5 text-sm font-medium text-green-800 border-solid border border-green-100 box-border">
                                        Public
                                    </span>
                                )}
                                {/* private badge */}
                                {!currentTemplate.public && (
                                    <span className="inline-flex items-center rounded-full bg-blue-50 px-3 py-0.5 text-sm font-medium text-blue-800 border-solid border border-blue-100">
                                        Private
                                    </span>
                                )}

                                {/* active badge */}
                                {currentTemplate.active && (
                                    <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-sm font-medium text-green-800 ml-4">
                                        <svg
                                            className="-ml-1 mr-1.5 h-2 w-2 text-green-400"
                                            fill="currentColor"
                                            viewBox="0 0 8 8"
                                        >
                                            <circle cx={4} cy={4} r={3} />
                                        </svg>
                                        Active
                                    </span>
                                )}
                                {/* inactive badge */}
                                {!currentTemplate.active && (
                                    <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800 ml-4">
                                        <svg
                                            className="-ml-1 mr-1.5 h-2 w-2 text-gray-400"
                                            fill="currentColor"
                                            viewBox="0 0 8 8"
                                        >
                                            <circle cx={4} cy={4} r={3} />
                                        </svg>
                                        Inactive
                                    </span>
                                )}
                            </div>
                        )}
                    </header>

                    {currentTemplate && (
                        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[minmax(0,_320px)_minmax(0,_320px)_minmax(0,_320px)] px-4 py-4 pb-6 rounded-lg shadow-md bg-slate-50">
                            {/* col one */}
                            <div className="pr-4 mr-8 border-0 border-r lg:border-solid border-slate-200">
                                {/* name and description */}
                                <div className="mt-4 font-semibold leading-none text-gray-500">
                                    {currentTemplate.name}
                                </div>
                                <div className="my-0 text-gray-400">
                                    {currentTemplate.description || <i>No description.</i>}
                                </div>

                                {/* owner */}
                                <div className="mt-4">
                                    <div className="leading-none text-gray-400 ">Owner</div>
                                    <div className="my-0 text-gray-800">{currentTemplate.owner}</div>
                                </div>

                                {/* environment action */}
                                <div className="mt-4 leading-none text-gray-400">Environment Action</div>
                                <div className="my-0 text-gray-800">{currentTemplate.environment_action}</div>

                                {/* product id */}
                                <div className="mt-4 leading-none text-gray-400">Product (id)</div>
                                <div className="my-0 text-gray-800">{currentTemplate.product_id}</div>

                                {/* tags */}
                                <div className="grid mt-4 text-gray-400 gap-x-2 grid-cols-[max-content_1fr]">
                                    <div>Tags</div>
                                    <div className="flex flex-wrap text-gray-800">
                                        {currentTemplate.tags?.length > 0 ? (
                                            currentTemplate.tags.map((tag: string) => (
                                                <span
                                                    key={tag}
                                                    className="px-2 py-0.5 my-1 rounded-lg bg-slate-200 text-slate-600 text-xs mr-1"
                                                >
                                                    {tag}
                                                </span>
                                            ))
                                        ) : (
                                            <i className="text-gray-400">None</i>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* col two */}
                            <div className="pr-16 mt-8 mr-8 border-0 border-r md:mt-0 lg:border-solid border-slate-200">
                                {/* table time info */}
                                <div className="table mt-4">
                                    {/* icon time */}
                                    <span className="text-indigo-500">{svgIconTime}</span>

                                    {/* default time */}
                                    <div className="table-row ">
                                        <div className="table-cell py-2 pr-6 border-0 border-b border-gray-200 border-solid">
                                            <div className="leading-none text-gray-400">Default Time</div>
                                        </div>
                                        <div className="table-cell py-2 border-0 border-b border-gray-200 border-solid">
                                            <div className="text-gray-800 ">{currentTemplate.default_time}</div>
                                        </div>
                                    </div>
                                    {/* additional time */}
                                    <div className="table-row">
                                        <div className="table-cell py-2 pr-6 border-0 border-b border-gray-200 border-solid">
                                            <div className="leading-none text-gray-400 ">Additional Time</div>
                                        </div>
                                        <div className="table-cell py-2 border-0 border-b border-gray-200 border-solid">
                                            <div className="text-gray-800 ">{currentTemplate.additional_time}</div>
                                        </div>
                                    </div>
                                    {/* grace time */}
                                    <div className="table-row">
                                        <div className="table-cell py-2 pr-6">
                                            <div className="leading-none text-gray-400 ">Grace Time</div>
                                        </div>
                                        <div className="table-cell py-2">
                                            <div className="text-gray-800 ">{currentTemplate.grace_time}</div>
                                        </div>
                                    </div>
                                </div>

                                {/* dates */}
                                <div className="mt-8">
                                    <div className="w-5 h-5 text-violet-500">{svgIconDate}</div>

                                    {/* updated, created */}
                                    <div className="mt-4">
                                        {/* updated at */}
                                        <div className="leading-none text-gray-400 ">Updated at</div>
                                        <div className="my-0 text-gray-400">{currentTemplate.updated_at}</div>

                                        {/* created at */}
                                        <div className="mt-4 leading-none text-gray-400">Created at</div>
                                        <div className="my-0 text-gray-400">{currentTemplate.created_at}</div>
                                    </div>
                                </div>
                            </div>

                            {/* col three */}
                            <div className="pr-16 mt-8 mr-8 text-left lg:mt-0">
                                {/* table time info */}
                                <div className="table mt-4">
                                    <span className="text-purple-500">{svgIconInfo}</span>
                                    {/* cost */}
                                    {false && (
                                        <div className="table-row ">
                                            <div className="table-cell py-2 pr-6 border-0 border-b border-gray-200 border-solid">
                                                <div className="leading-none text-gray-400">Cost</div>
                                            </div>
                                            <div className="table-cell py-2 border-0 border-b border-gray-200 border-solid">
                                                <div className="text-gray-800">
                                                    {currentTemplate?.cost ? (
                                                        <span>{JSON.stringify(currentTemplate?.cost, null, 2)}</span>
                                                    ) : (
                                                        <span className="text-gray-400">none</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* manifest */}
                                    <div className="table-row">
                                        <div className="table-cell py-2 pr-6 leading-none text-gray-400 border-0 border-b border-gray-200 border-solid">
                                            Manifest
                                        </div>
                                        <div className="table-cell py-2 border-0 border-b border-gray-200 border-solid">
                                            {currentTemplate.manifest ? (
                                                currentTemplate.manifest
                                            ) : (
                                                <span className="text-gray-400">none</span>
                                            )}
                                        </div>
                                    </div>

                                    {/* parent template id */}
                                    <div className="table-row">
                                        <div className="table-cell py-2 pr-6 leading-none text-gray-400 border-0 border-b border-gray-200 border-solid">
                                            Parent Template
                                        </div>
                                        <div className="table-cell py-2 border-0 border-b border-gray-200 border-solid">
                                            {currentTemplate.parent_template_id ? (
                                                currentTemplate.parent_template_id
                                            ) : (
                                                <span className="text-gray-400">none</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}

                    {/* bottom section */}
                    {currentTemplate && (
                        <section className="grid grid-cols-1 mt-4 lg:grid-cols-2 gap-x-4">
                            {/* chart */}
                            <section className="flex p-4 overflow-scroll rounded-lg shadow-md bg-slate-100">
                                <div className="">
                                    <section className="">
                                        <div className="font-semibold leading-none text-slate-500">Chart</div>
                                        <div className="my-0 mt-1 leading-none text-slate-500">
                                            {currentTemplate.chart.name}
                                        </div>

                                        {/* chart repo url */}
                                        <div className="my-0 mt-4 text-gray-400">Repo</div>
                                        <div className="my-0 text-xs text-slate-500">
                                            {currentTemplate.chart.repo_url || <i>No repo.</i>}
                                        </div>

                                        {/* chart path */}
                                        <div className="my-0 mt-4 text-gray-400">Path</div>
                                        <div className="my-0 font-mono text-xs text-slate-500">
                                            {currentTemplate.chart.path}
                                        </div>
                                    </section>
                                </div>
                            </section>

                            {/* extra arguments */}
                            <section className="p-4 mt-4 overflow-scroll rounded-lg shadow-md lg:mt-0 bg-slate-100">
                                <div className="font-semibold leading-none text-slate-500">
                                    <div className="">Extra Arguments</div>
                                </div>

                                <pre
                                    className="overflow-scroll whitespace-pre-wrap text-slate-500"
                                    style={{ wordWrap: 'break-word' }}
                                >
                                    {JSON.stringify(currentTemplate?.extra_args, null, 2)}
                                </pre>
                            </section>
                        </section>
                    )}
                </div>
            )}

            {/* error templates */}
            {mutationKTemplates.isError && (
                <div className="my-8">
                    <div className="inline-block p-2 overflow-scroll text-red-500 rounded-md bg-red-50">
                        <div>
                            <span className="font-semibold">
                                Error {(mutationKTemplates.error as FetchBaseQueryError).status}
                            </span>{' '}
                            Cannot fetch data for templates.
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Templates
