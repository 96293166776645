import { IKCluster, IKInstance, IKTemplate } from '../core-types'

/**
 * Get a Kubeark instance from an array of Kubeark instances.
 *
 * @param id Kubeark database id
 * @param kubearkInstances Array of kubeark instances
 */
export function getKubearkInstanceFromInstances(id: number, kubearkInstances: IKInstance[]): IKInstance | undefined {
    return kubearkInstances?.find((kubearkInstance: IKInstance) => {
        return kubearkInstance.id === id
    })
}

/**
 * Check if a K instance name alread exists in an array of K instances.
 *
 * @param name
 * @param kubearkInstances
 * @param excludeName Exclude this name from search; usually current name.
 * @returns boolean
 */
export function isKubearkInstanceNameTaken(
    name: string,
    kubearkInstances: IKInstance[],
    excludeName: string = ''
): boolean {
    return kubearkInstances.findIndex(
        (kubearkInstance: IKInstance) => kubearkInstance.name === name && kubearkInstance.name !== excludeName
    ) > -1
        ? true
        : false
}

/**
 * Get a template from an array of templates.
 *
 * @param id Template id
 * @param templates Array of templates
 * @returns The template for the specified id.
 */
export const getTemplateFromTemplates = (
    id: number | undefined,
    templates: IKTemplate[] | undefined
): IKTemplate | undefined => {
    // NOTE Make sure I don't ommit id = 0 as a falsy value
    if ((id || id === 0) && templates) {
        return templates.find((template: IKTemplate) => template.id === id)
    }
    return undefined
}

/**
 * Get a cluster from an array of clusters.
 *
 * @param id
 * @param clusters
 * @returns The cluster for the specified id.
 */
export const getClusterFromClusters = (
    id: number | undefined,
    clusters: IKCluster[] | undefined
): IKCluster | undefined => {
    // NOTE Make sure I don't ommit id = 0 as a falsy value
    if ((id || id === 0) && clusters) {
        return clusters.find((cluster: IKCluster) => cluster.id === id)
    } else {
        return undefined
    }
}
