import { Breadcrumb, Modal } from 'antd'
import { useMemo } from 'react'
import { useMatches } from 'react-router-dom'

const { confirm } = Modal

// Confirmation modal to exit the page
export const showConfirmQuitEditingModal = (navigate: any) => {
    confirm({
        icon: null,
        content: `Quit adding the offer?`,
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        async onOk() {
            navigate('/offers')
        },
        onCancel() {},
    })
}

const Breadcrumbs = () => {
    // HOOKS

    // I use this handle with data from a Route 'params',
    //  but I can also use it with Route 'data' from the loader
    const matches = useMatches()
    const crumbs = useMemo(
        () =>
            matches
                .filter((match: any) => Boolean(match.handle?.crumb))
                .map((match: any) => match.handle.crumb(match.params)),
        [matches]
    )

    // JSX
    return (
        <Breadcrumb className="breadcrumbs">
            {crumbs.map((crumb: any, index: number) => (
                <Breadcrumb.Item key={crumb}>{crumb}</Breadcrumb.Item>
            ))}
        </Breadcrumb>
    )
}

export default Breadcrumbs
