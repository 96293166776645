import { Link } from 'react-router-dom'
import { Button, Typography } from 'antd'
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

type Props = {
    id: number
    // handlers
    isLoadingDelete: boolean
    onDeleteOfferClick: any
}

const MainHeaderOfferDetails = ({ id, onDeleteOfferClick, isLoadingDelete }: Props) => {
    // JSX
    return (
        <header className="grid grid-cols-[max-content_minmax(0,_1fr)_minmax(0,_1fr)] grid-rows-[max-content] items-center">
            {/* arrow */}
            <div className="self-center">
                <Link to={'/offers'} className="ml-[-32px] w-[32px]">
                    <ArrowLeftOutlined className="text-[18px]" />
                </Link>
            </div>

            {/* left side */}
            <div className="self-center">
                <Title level={2} className="!my-0">
                    Offer
                </Title>
            </div>

            {/* right side */}
            <div className="self-center text-right">
                {/* Delete Offer */}
                <Button danger onClick={onDeleteOfferClick} disabled={isLoadingDelete}>
                    <DeleteOutlined />
                </Button>
            </div>

            <div className="col-span-2">
                <Text type="secondary">Offer details.</Text>
            </div>
        </header>
    )
}

export default MainHeaderOfferDetails
