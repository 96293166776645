import { Button, Typography, message, theme } from 'antd'
import { prettyDate } from '../../../utilities/tools'
import { svgIconInstance, svgIconDate } from '../../../svgs'
import type { IOffer } from '../../../core-types'
import { useDrawerOfferOverview } from '../hooks/useDrawerOfferOverview'
import { useCallback, useEffect } from 'react'
import { useUpdateOfferMutation } from '../../api/apiSlice'
import DrawerOfferOverview from './DrawerOfferOverview'
import FormOffer from './FormOffer'
import InfoItem from './InfoItem'
import useOfferQueries from '../hooks/useOfferQueries'
import useOfferInfo from '../hooks/useOfferInfo'

const { useToken } = theme
const { Text } = Typography

type Props = {
    currentOffer: IOffer
}

const TabOverview = ({ currentOffer }: Props) => {
    // HOOKS

    const { token } = useToken()

    // offer info queries
    const queriesOfferInfo = useOfferQueries(currentOffer.kubeark_id, currentOffer.template_id)
    // offer info
    const { itemNames, itemLinks, itemActiveStates } = useOfferInfo(currentOffer, queriesOfferInfo)

    // update offer mutation
    const [
        updateOffer,
        {
            isLoading: isLoadingOfferUpdate,
            isSuccess: isSuccessOfferUpdate,
            isError: isErrorOfferUpdate,
            error: errorOfferUpdate,
        },
    ] = useUpdateOfferMutation()

    // drawer hook
    const {
        open: isOpenDrawerOfferOverview,
        isDirty: isSubmitDirty,
        setIsDirty: setIsSubmitDirty,
        setOpen: setIsOpenDrawerOfferOverview,
        onClose: onCloseDrawerOfferOverview,
    } = useDrawerOfferOverview()

    // callback to update offer drawer `isOpen` state,
    // so I avoid passing `setIsOpenDrawerOfferOverview` as an argument to `useFormOffer`
    const updateIsOpenDrawerOfferOverview = useCallback(
        (isOpen: boolean): void => {
            setIsOpenDrawerOfferOverview(isOpen)
        },
        [setIsOpenDrawerOfferOverview]
    )

    // callback to update the offer
    const offerUpdateMethod = useCallback(
        async (updatedOfferData: any) => {
            await updateOffer(updatedOfferData)
        },
        [updateOffer]
    )

    // offer update success
    useEffect(() => {
        if (isSuccessOfferUpdate) {
            message.success('Offer updated.')
            setIsOpenDrawerOfferOverview(false)
        }
    }, [isSuccessOfferUpdate, setIsOpenDrawerOfferOverview])

    // offer update error
    useEffect(() => {
        if (isErrorOfferUpdate && errorOfferUpdate) {
            message.error({
                content: `Cannot update offer: ${errorOfferUpdate.data.error}`,
                duration: 5,
            })
        }
    }, [isErrorOfferUpdate, errorOfferUpdate])

    // HANDLERS

    // open the drawer to edit the offer
    const onEditOfferClick = useCallback(() => {
        setIsOpenDrawerOfferOverview(true)
    }, [setIsOpenDrawerOfferOverview])

    // JSX

    return (
        <>
            {/* <DebugTabOverview currentOffer={currentOffer} /> */}

            {/* Drawer - Offer Edit */}
            {currentOffer && (
                <DrawerOfferOverview
                    title="Edit Offer"
                    isOpen={isOpenDrawerOfferOverview}
                    isLoading={isLoadingOfferUpdate}
                    onClose={onCloseDrawerOfferOverview}
                    isDirty={isSubmitDirty}
                >
                    <FormOffer
                        offer={currentOffer}
                        setIsDirty={setIsSubmitDirty}
                        updateIsOpenParent={updateIsOpenDrawerOfferOverview}
                        offerUpdateMethod={offerUpdateMethod}
                    />
                </DrawerOfferOverview>
            )}

            {/* success */}
            {currentOffer && (
                <div
                    className="grid grid-rows-[mx-content_1fr] rounded-md shadow-md border-solid border"
                    style={{ borderColor: token.colorBgLayout }}
                >
                    {/* header */}
                    <header className="grid grid-cols-1 gap-8 lg:grid-cols-[minmax(max-content,_80px)_max-content_1fr] p-4">
                        {/* col */}
                        <div>
                            <Text className="block" type="secondary">
                                Offer
                            </Text>
                            <Text className="font-semibold">{currentOffer.offer_id}</Text>
                        </div>

                        {/* col */}

                        <div className="lg:pl-4">
                            <Text className="block" type="secondary">
                                Plan Id
                            </Text>
                            <Text>{currentOffer.plan_id}</Text>
                        </div>

                        {/* col */}
                        <div className="flex flex-row items-center lg:justify-end">
                            <Button onClick={onEditOfferClick}>Edit</Button>
                        </div>
                    </header>

                    {/* body */}
                    <main
                        className="grid grid-cols-1 px-4 py-8 lg:grid-cols-2"
                        style={{ backgroundColor: token.colorBgLayout }}
                    >
                        <div className="grid gap-x-8 grid-cols-1 lg:grid-cols-[max-content_max-content]">
                            {itemNames.kInstanceName && (
                                <div
                                    className="pr-4 border-0 border-solid lg:pr-16 lg:border-r"
                                    style={{ borderColor: token.colorBorder }}
                                >
                                    {/* icon */}
                                    <Text style={{ color: token.colorPrimary }}>
                                        <div className="w-5 h-5">{svgIconInstance}</div>
                                    </Text>

                                    <div className="mt-6">
                                        {/* instance */}
                                        <Text className="block" type="secondary">
                                            Kubeark Instance
                                        </Text>
                                        <InfoItem
                                            query={queriesOfferInfo.queryKInstances}
                                            errorMessage="Cannot fetch Kubeark instance."
                                            link={itemLinks.linkKInstance}
                                            itemText={itemNames.kInstanceName}
                                            isActive={itemActiveStates.isKInstanceActive}
                                        />

                                        {/* template */}
                                        <Text className="block mt-4" type="secondary">
                                            Template
                                        </Text>
                                        <InfoItem
                                            query={queriesOfferInfo.mutationKTemplates}
                                            errorMessage="Cannot fetch template."
                                            link={itemLinks.linkKTemplate}
                                            itemText={itemNames.kTemplateName}
                                            isActive={itemActiveStates.isKTemplateActive}
                                        />

                                        {/* template version */}
                                        {currentOffer.template_version_id && (
                                            <>
                                                <Text className="block mt-4" type="secondary">
                                                    Template Version
                                                </Text>
                                                <InfoItem
                                                    query={queriesOfferInfo.mutationKTemplateVersions}
                                                    errorMessage="Cannot fetch template version."
                                                    link={''} // itemLinks.linkKTemplateVersion (template versions not supported in Kubeark section)
                                                    itemText={itemNames.kTemplateVersionName}
                                                    isActive={itemActiveStates.isKTemplateVersionActive}
                                                />
                                            </>
                                        )}

                                        {/* cluster */}
                                        <Text className="block mt-4" type="secondary">
                                            Cluster
                                        </Text>
                                        <InfoItem
                                            query={queriesOfferInfo.queryKClusters}
                                            errorMessage="Cannot fetch cluster."
                                            link={itemLinks.linkKCluster}
                                            itemText={itemNames.kClusterName}
                                            isActive={itemActiveStates.isKClusterActive}
                                        />
                                    </div>
                                </div>
                            )}

                            {/* dates */}
                            <div className="pt-8 pr-4 lg:pt-0 lg:pr-16">
                                {/* icon */}
                                <Text style={{ color: token.colorPrimary }}>
                                    <div className="w-5 h-5">{svgIconDate}</div>
                                </Text>

                                {/* Updated at */}
                                <Text className="block mt-4" type="secondary">
                                    Last update
                                </Text>
                                <Text>{prettyDate(new Date(currentOffer.updated_at))}</Text>

                                {/* Created at */}
                                <Text className="block mt-4" type="secondary">
                                    Created at
                                </Text>
                                <Text>{prettyDate(new Date(currentOffer.created_at))}</Text>
                            </div>
                        </div>
                    </main>
                </div>
            )}
        </>
    )
}

export default TabOverview
