import React, { useMemo } from 'react'
// antd
import { Tabs } from 'antd'
import { ClusterOutlined, ProfileOutlined, CloudServerOutlined } from '@ant-design/icons'
import InstanceDetails from '../instanceDetails'
import Templates from '../templates'
import Clusters from '../clusters'
import type { IKInstance } from '../../../core-types'
import { useSyncActiveTabAndSearchParams } from '../../hooks/useSyncActiveTabAndSearchParams'

const makeTabsContent = (kubearkInstance: IKInstance): any => [
    // instance  overview
    {
        label: (
            <span>
                <CloudServerOutlined />
                Overview
            </span>
        ),
        key: 'overview',
        children: <InstanceDetails kubearkInstance={kubearkInstance} />,
    },
    //  templates
    {
        label: (
            <span>
                <ProfileOutlined />
                Templates
            </span>
        ),
        key: 'templates',
        children: <Templates kubearkInstance={kubearkInstance} />,
    },
    // clusters
    {
        label: (
            <span>
                <ClusterOutlined />
                Clusters
            </span>
        ),
        key: 'clusters',
        children: <Clusters kubearkInstance={kubearkInstance} />,
    },
]

interface IProps {
    kubearkInstance: IKInstance
}

export const KubearkDetails: React.FC<IProps> = ({ kubearkInstance }: IProps) => {
    // HOOKS

    const { activeTabKey, onChangeTabs } = useSyncActiveTabAndSearchParams({ defaultTabKey: 'overview' })

    // tabs content moved outside the component
    const tabs = useMemo(() => makeTabsContent(kubearkInstance), [kubearkInstance])

    // JSX
    return (
        <>
            <div className="kubeark-details">
                <Tabs
                    className="mt-4"
                    defaultActiveKey="0"
                    activeKey={activeTabKey}
                    onChange={onChangeTabs}
                    items={tabs.map((tab: any) => {
                        return tab
                    })}
                />
            </div>
        </>
    )
}
