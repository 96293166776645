import { useCallback, useState } from 'react'

export const useDrawerOfferOverview = () => {
    // HOOKS
    const [open, setOpen] = useState(false)
    const [isDirty, setIsDirty] = useState(false)

    const onClose = useCallback(() => {
        setOpen(false)
        setIsDirty(false) // more like a fix? `isDirty` does not get set to `false` from `handleFormChange` when I open the drawer again
    }, [])

    return {
        // drawer state
        open,
        setOpen,
        // dirty submit button
        isDirty,
        setIsDirty,
        // handlers
        onClose,
    }
}
