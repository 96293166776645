import { createSlice } from '@reduxjs/toolkit'

interface IAuthAction {
    payload: {
        user: string | undefined
        access_token: string | null
        refresh_token: string | null
    }
}

interface IAuthState {
    user: string | undefined
    token: string | null
    refresh: string | null
}

const access_token = localStorage.getItem('access_token')
const refresh_token = localStorage.getItem('refresh_token')

// NOTE AUTH Initial values have to be set from persistent storage if they exist there.
const initialState: IAuthState = {
    user: 'test' ?? undefined,
    token: access_token ?? null,
    refresh: refresh_token ?? null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state: IAuthState, action: IAuthAction) => {
            const { user, access_token, refresh_token } = action.payload
            state.user = user
            state.token = access_token
            state.refresh = refresh_token
        },
        // setAccessToken: (state: IAuthState, action: IAuthAction) => {
        //     state.token = action.payload.access_token
        // },
        logOut: (state: IAuthState, action: IAuthAction) => {
            state.user = undefined
            state.token = null
            state.refresh = null
        },
    },
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

// define some selectors
export const selectCurrentUser = (state: any) => state.auth.user
export const selectCurrentToken = (state: any) => state.auth.token
export const selectCurrentRefreshToken = (state: any) => state.auth.refresh
