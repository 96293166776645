import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCredentials } from '../../auth/authSlice'

const useAuthPersistentCredentials = () => {
    const dispatch = useDispatch()

    // read the tokens from local storage and set the state
    useEffect(() => {
        const access_token = localStorage.getItem('access_token')
        const refresh_token = localStorage.getItem('refresh_token')

        if (access_token) {
            dispatch(setCredentials({ access_token: access_token, refresh_token: refresh_token, user: 'test' }))
        }
    }, [dispatch])

    return {}
}

export default useAuthPersistentCredentials
