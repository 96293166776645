import React from 'react'
import { Button, Typography } from 'antd'
import useLogin from '../hooks/useLogin'

import logoFull from '../../../img/logo-full-mono.svg'

import config from '../../../config'
import { svgAzure, svgKubeark, svgKubearkMono } from '../../../svgs'
import { useVersionQuery } from '../../api/apiSlice'

const { Text } = Typography

export const PageLogin: React.FC = () => {
    // HOOKS
    const {
        handleLogin,

        isLoadingLoginQuery,
        isErrorLoginQuery,
        errorLoginQuery,
    } = useLogin()

    // version
    const { data: dataVersion } = useVersionQuery({})

    // JSX
    return (
        <div className="grid grid-cols-2 -mt-24 overflow-hidden  shadow-xl bg-gradient-to-bl from-[#0c043a] from-10% via-[#870bff] via-50% to-[#ff5a5f] to-90%">
            {/* col left */}
            <div className="relative flex items-center justify-center p-4 min-h-[320px]">
                {/* logo */}
                <div className="flex items-center mx-4">
                    <img src={logoFull} className="h-[96px]" alt="App Logo" />{' '}
                </div>
                <div className="absolute bottom-0 right-0 p-2 font-sans text-xs text-right text-white/90">
                    Version {config.version}
                    <br />
                    API Version {dataVersion?.version ?? <span>...</span>}
                </div>
            </div>

            {/* col right */}
            <div className="flex flex-col items-center justify-center p-12 text-left bg-white w-80">
                <div className="w-full mb-8 text-left">
                    <div className="flex items-center mb-4">
                        <div className="text-black/30 w-[34px] h-[34px]  inline-block mr-1">{svgKubeark}</div>
                        <div className="text-[#0072c6] w-[36px] h-[36px] inline-block mr-1 -mt-2">{svgAzure}</div>
                    </div>

                    <Text className="font-bold text-[#0c043a]">Kubeark Transactable for Azure</Text>

                    <div>
                        {!isLoadingLoginQuery ? (
                            <Text className="text-[#0c043a] opacity-60">Log in with your Kubeark account.</Text>
                        ) : (
                            <Text type="secondary">Logging in...</Text>
                        )}

                        {/* error */}
                        {isErrorLoginQuery && (
                            <div>
                                <Text type="danger">Error: Cannot perform login query.{errorLoginQuery.message}</Text>
                            </div>
                        )}
                    </div>
                </div>

                <div className="w-full">
                    <Button
                        onClick={handleLogin}
                        type="primary"
                        className="flex items-center justify-center px-5 py-6 bg-[#ff5a5f] hover:!bg-[#ff5a5f] active:opacity-20 active:!bg-[#ff5a5f]"
                        disabled={isLoadingLoginQuery}
                    >
                        <span className="w-6 h-6 mr-1 ">{svgKubearkMono}</span> Kubeark Login
                    </Button>
                </div>
            </div>
        </div>
    )
}
