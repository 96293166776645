export const svgChevronRight = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
    >
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
    </svg>
)

export const svgIconInstance = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z"
        />
    </svg>
)

export const svgIconDate = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
        />
    </svg>
)

export const svgIconInfo = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-5 h-6 "
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
        />
    </svg>
)

export const svgIconApi = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
        />
    </svg>
)

export const svgIconApps = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-4 h-4"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
        />
    </svg>
)

export const svgIconVersions = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-4 h-4"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z"
        />
    </svg>
)

export const svgIconTime = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-5 h-5 "
    >
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
)

export const svgKubearkMono = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 268 268">
        <path fill="currentColor" d="M66.79 133.59l111.32 133.59v-77.91l-44.53-55.66-66.79-.02z"></path>
        <path fill="currentColor" d="M111.32.01L178.11 0 66.79 133.59 0 133.61 111.32.01z"></path>
    </svg>
)

export const svgKubeark = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 268 268">
        <path fill="#FF5A5F" d="M66.79 133.59l111.32 133.59v-77.91l-44.53-55.66-66.79-.02z"></path>
        <path fill="#150359" d="M111.32.01L178.11 0 66.79 133.59 0 133.61 111.32.01z"></path>
    </svg>
)

export const svgAzure = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -16.33 161.67 161.67">
        <path
            fill="currentColor"
            d="M88.33 0L40.67 41.33 0 114.33h36.67zm6.34 9.67L74.33 67l39 49-75.66 13h124z"
        ></path>
    </svg>
)

export const svgDarkMode = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" style={{ verticalAlign: '-0.125em' }}>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g fill="currentColor" fillRule="nonzero" transform="translate(-9 -49.5)">
                <g transform="translate(0 42.5)">
                    <g transform="translate(9.269 7.5)">
                        <path d="M8 1.333c.15 0 .297.005.443.016a4.66 4.66 0 00-.073 3.797 4.69 4.69 0 00.995 1.49 4.69 4.69 0 001.489.994 4.66 4.66 0 003.797-.073 6.607 6.607 0 01-.224 2.213 6.625 6.625 0 01-.669 1.597 6.707 6.707 0 01-1.044 1.347 6.65 6.65 0 01-1.347 1.044c-.488.286-1.02.51-1.596.67-.576.16-1.167.24-1.771.239-.604 0-1.195-.08-1.77-.24a6.605 6.605 0 01-1.597-.669 6.715 6.715 0 01-1.347-1.044 6.658 6.658 0 01-1.044-1.347 6.655 6.655 0 01-.67-1.596A6.597 6.597 0 011.334 8c0-.605.08-1.195.24-1.77.16-.576.382-1.108.669-1.597a6.658 6.658 0 011.044-1.347c.41-.409.858-.757 1.347-1.044a6.605 6.605 0 011.596-.67A6.638 6.638 0 018 1.334zM6.687 2.828a5.233 5.233 0 00-1.601.708c-.495.326-.92.713-1.276 1.161a5.279 5.279 0 00-.839 1.529 5.253 5.253 0 00-.304 1.773c0 .723.14 1.413.422 2.07.281.658.66 1.226 1.138 1.704A5.343 5.343 0 005.93 12.91c.658.28 1.348.421 2.07.422.611 0 1.202-.102 1.773-.305a5.279 5.279 0 001.529-.839 5.445 5.445 0 001.161-1.276 5.233 5.233 0 00.708-1.601c-.149.014-.317.02-.505.02a5.836 5.836 0 01-2.328-.476 6.055 6.055 0 01-1.914-1.281A6.055 6.055 0 017.143 5.66a5.836 5.836 0 01-.477-2.328c0-.188.007-.356.02-.506l.001.001z"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)
