import { useNavigate } from 'react-router-dom'
import { prettyDate } from '../../../utilities/tools'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { IOffer } from '../../../core-types'
import Table, { ColumnType } from 'antd/es/table'
import { theme } from 'antd'

const { useToken } = theme

interface IProps {
    offersQuery: any
    searchedText: string
}

const onFilterAll = (value: string | number | boolean, record: IOffer) =>
    record.offer_id
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) ||
    record.plan_id.toLowerCase().includes((value as string).toLowerCase()) ||
    new Date(record.updated_at)
        .toLocaleString()
        .toLowerCase()
        .includes((value as string).toLowerCase())

const ListOffers: React.FC<IProps> = ({ offersQuery, searchedText }: IProps) => {
    // HOOKS

    const { token } = useToken()

    const navigate = useNavigate()

    // table columns
    const columns: ColumnType<IOffer>[] = [
        {
            title: 'Offer',
            key: 'offer_id',
            dataIndex: 'offer_id',
            render: (value) => <span style={{ color: token.colorLink }}>{value}</span>,
            sorter: (a, b) => a.offer_id.localeCompare(b.offer_id),
            filteredValue: [searchedText],
            onFilter: onFilterAll,
        },
        {
            title: 'Plan',
            key: 'plan_id',
            dataIndex: 'plan_id',
            sorter: (a, b) => a.plan_id.localeCompare(b.plan_id),
        },
        {
            title: 'Updated',
            key: 'updated_at',
            dataIndex: 'updated_at',
            render: (value) => prettyDate(new Date(value)),
            sorter: (a, b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
            defaultSortOrder: 'descend',
        },
    ]

    // HANDLERS

    const rowClickHandler = (record: IOffer) => {
        navigate(`${record.id}`)
    }

    // JSX

    return (
        <>
            {/* table */}
            <Table
                columns={columns}
                dataSource={offersQuery.data}
                pagination={{ size: 'default' }}
                loading={offersQuery.isLoading}
                rowClassName="cursor-pointer "
                rowKey="run_id"
                onRow={(record: IOffer) => {
                    return {
                        onClick: () => {
                            rowClickHandler(record)
                        },
                    }
                }}
            />

            {/* error */}
            {offersQuery.isError && (
                <div>
                    <div className="inline-block p-2 overflow-scroll text-red-500 rounded-md bg-red-50">
                        <div>
                            <span className="font-semibold">
                                Error {(offersQuery.error as FetchBaseQueryError).status}
                            </span>{' '}
                            Cannot fetch offers.
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ListOffers
