// FEATURE auth - Use this component to protect routes

import { useLocation, Navigate, Outlet } from 'react-router-dom'
// redux
import { useSelector } from 'react-redux'
import { selectCurrentToken } from './authSlice'
import useAuthPersistentCredentials from '../groupAuth/hooks/useAuthPersistentCredentials'

type Props = {}

// NOTE AUTH

const RequireAuth = (props: Props) => {
    // HOOKS
    useAuthPersistentCredentials()
    const token = useSelector(selectCurrentToken)
    const location = useLocation()

    // NOTE JWT user roles would be implemented here...

    // JSX
    // If there is no token, navigate to the /login paage (basically log out)
    return token ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
}

export default RequireAuth
