import { useMemo } from 'react'
import { IKCluster, IKTemplate, IKInstance } from '../../../core-types'
import { SelectOption } from '../types'

/**
 * Returns memos with <Select> options for the specified queries
 *
 * @param queryKInstances
 * @param mutationKTemplates
 * @param mutationKTemplateVersions
 * @param queryKClusters
 * @returns
 */
const useOfferSelectOptions = (
    queryKInstances: any,
    mutationKTemplates: any,
    mutationKTemplateVersions: any,
    queryKClusters: any
) => {
    //
    // options K instances
    //

    const optionsKInstances: SelectOption[] = useMemo(() => {
        if (queryKInstances.isSuccess) {
            return queryKInstances.data.map((instance: IKInstance) => ({
                value: instance.id,
                label: instance.name,
            }))
        }
        return []
    }, [queryKInstances.isSuccess, queryKInstances.data])

    //
    // options for K templates (active templates only)
    //

    const activeTemplates: IKTemplate[] = useMemo(() => {
        return (
            mutationKTemplates.isSuccess &&
            mutationKTemplates.data.templates.filter((template: IKTemplate) => template.active)
        )
    }, [mutationKTemplates.isSuccess, mutationKTemplates.data])

    const optionsKTemplates = useMemo(() => {
        if (mutationKTemplates.isSuccess) {
            return activeTemplates.map((template: IKTemplate) => ({
                value: template.id,
                label: template.name,
            }))
        }
        return []
    }, [activeTemplates, mutationKTemplates.isSuccess])

    //
    // options for K template versions (active template versions only)
    //

    const activeTemplateVersions: IKTemplate[] = useMemo(() => {
        return (
            mutationKTemplateVersions.isSuccess &&
            mutationKTemplateVersions.data.templates.filter((template: IKTemplate) => template.active)
        )
    }, [mutationKTemplateVersions.isSuccess, mutationKTemplateVersions.data])

    const optionsKTemplateVersions = useMemo(() => {
        if (mutationKTemplateVersions.isSuccess) {
            return activeTemplateVersions.map((template: IKTemplate) => ({
                value: template.id,
                label: template.name,
            }))
        }
        return []
    }, [activeTemplateVersions, mutationKTemplateVersions.isSuccess])

    //
    // options for K clusters (active clusters only)
    //

    const activeClusters: IKCluster[] = useMemo(() => {
        return queryKClusters.isSuccess && queryKClusters.data.filter((cluster: IKCluster) => cluster.active)
    }, [queryKClusters.isSuccess, queryKClusters.data])

    // options for clusters <Select>
    const optionsKClusters = useMemo(() => {
        if (queryKClusters.isSuccess) {
            return activeClusters.map((cluster: IKCluster) => ({
                value: cluster.id,
                label: cluster.name,
            }))
        } else {
            return []
        }
    }, [queryKClusters.isSuccess, activeClusters])

    return {
        optionsKInstances,
        optionsKTemplates,
        optionsKTemplateVersions,
        optionsKClusters,
    }
}

export default useOfferSelectOptions
