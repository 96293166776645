import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { Layout } from 'antd'

import { usePrefetch } from '../../api/apiSlice'

import Sidebar from '../sidebar/sidebar'
import MainMenu from '../mainMenu/mainMenu'
import MainContent from '../mainContent/mainContent'
import MainFooter from '../mainFooter/mainFooter'
import './layoutDefault.scss'

// FEATURE RTKQ: Prefetch specific queries.
// Custom hook to prefetch the main queries
const usePrefetchMainQueries = () => {
    const prefetchOffers = usePrefetch('getOffers')
    const prefetchOrders = usePrefetch('getOrders')
    const prefetchKubearkInstances = usePrefetch('getKubearkInstances')

    useEffect(() => {
        prefetchOffers({})
        prefetchOrders({})
        prefetchKubearkInstances({})

        // prefetch with options
        // prefetchOrders(
        //     {},
        //     {
        //         ifOlderThan: 60,
        //     }
        // )
    }, [prefetchOrders, prefetchKubearkInstances, prefetchOffers])
}

type Props = {
    children?: React.ReactNode
}

const LayoutDefault: React.FC<Props> = () => {
    // HOOKS
    usePrefetchMainQueries()

    // JSX
    return (
        <Layout hasSider className="default-layout">
            <Sidebar />
            <MainMenu />
            <Layout className="layout-content">
                <MainContent>
                    <Outlet />
                </MainContent>
                <MainFooter />
            </Layout>
        </Layout>
    )
}

export default LayoutDefault
