import { Outlet } from 'react-router-dom'

// import logoSymbol from '../../../img/login/logo-symbol.svg'
import logoSymbol from '../../../img/logo-symbol.svg'

type Props = {
    children?: React.ReactNode
}

const LayoutLogin: React.FC<Props> = ({ children }) => {
    return (
        <div
            className="flex items-center justify-center h-screen bg-[#0c043a] login-layout"
            style={{
                backgroundImage: `url('${logoSymbol}')`,
                backgroundSize: '60%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
            }}
        >
            <Outlet />
        </div>
    )
}

export default LayoutLogin
