import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type Params = {
    defaultTabKey: string
}

/**
 * Custom hook to keep <Tabs> active tab and RR search params in sync.
 * Used with antd <Tabs>
 *
 * @param defaultTabKey A string representing the default active tab key, as defined inside <Tabs> items.
 * @returns activeTab: string - state for the active tab key used in <Tabs>
 * @returns onChangeTabs - event handler for <Tabs> `onChange`
 */
export const useSyncActiveTabAndSearchParams = ({ defaultTabKey }: Params) => {
    // HOOKS

    // Router
    const [searchParams, setSearchParams] = useSearchParams()

    const [activeTabKey, setActiveTab] = useState(defaultTabKey)

    // read the `tab` query param and set the active tab to it
    useEffect(() => {
        if (searchParams && searchParams.get('tab')) {
            const tab = searchParams.get('tab')
            if (typeof tab === 'string') {
                setActiveTab(tab)
            }
        } else {
            setActiveTab(defaultTabKey)
        }
    }, [searchParams, defaultTabKey])

    // HANDLERS

    // when the tab changes, it gets added into the query param `tab`
    const onChangeTabs = useCallback(
        (activeKey: string) => {
            setSearchParams({
                // NOTE router - this is how I get all the search params from an Iterator {}, in order to spread them.
                // I had to increase the TS target in `tsconfig.json` from `es5` to `es6` in order to be able to do this.
                ...Object.fromEntries([...searchParams]),
                tab: activeKey,
            })

            setActiveTab(activeKey)
        },
        [searchParams, setSearchParams, setActiveTab]
    )

    return {
        activeTabKey,
        onChangeTabs,
    }
}
