import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectCurrentToken } from '../../auth/authSlice'

// Initial navigation after authentication.
const useAuthDefaultNavigate = () => {
    const navigate = useNavigate()

    const currentToken = useSelector(selectCurrentToken)

    // if the currentToken state is set, navigate to the default page, `/dashboard`
    useEffect(() => {
        if (currentToken) {
            // NOTE important to navigate with `replace` true so we replace the login URL ( in history) that contains the tokens
            navigate('/dashboard', { replace: true })
        } else {
            navigate('/login', { replace: true })
        }
    }, [currentToken, navigate])

    return {}
}

export default useAuthDefaultNavigate
