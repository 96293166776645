import { Avatar, Table, Typography, theme } from 'antd'
import { prettyDate } from '../../../utilities/tools'
import { useNavigate } from 'react-router-dom'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { IOrder } from '../../../core-types'
import { ColumnType } from 'antd/es/table'
import { OrderStatus } from '../../components/OrderStatus'
import { DeploymentStatus } from '../../components/DeploymentStatus'

const { useToken } = theme
const { Text } = Typography

const onFilterAll = (value: string | number | boolean, record: IOrder) =>
    record.offer_id
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) ||
    record.plan_id.toLowerCase().includes((value as string).toLowerCase()) ||
    record.subscription_id?.toLowerCase().includes((value as string).toLowerCase()) ||
    record.status?.toLowerCase().includes((value as string).toLowerCase()) ||
    record.deployment_status?.toLowerCase().includes((value as string).toLowerCase()) ||
    record.client_name?.toLowerCase().includes((value as string).toLowerCase()) ||
    record.client_email?.toLowerCase().includes((value as string).toLowerCase()) ||
    prettyDate(new Date(record.updated_at))
        .toLocaleString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) ||
    prettyDate(new Date(record.created_at))
        .toLocaleString()
        .toLowerCase()
        .includes((value as string).toLowerCase())

type Props = {
    ordersQuery: any
    searchedText: string
}

const ListOrders = ({ ordersQuery, searchedText }: Props) => {
    // HOOKS
    const { token } = useToken()
    const navigate = useNavigate()

    // table columns
    const columns: ColumnType<IOrder>[] = [
        {
            title: 'Offer',
            key: 'offer_id',
            dataIndex: 'offer_id',
            render: (value) => <span className="">{value}</span>,
            sorter: (a, b) => a.offer_id.localeCompare(b.offer_id),
            // NOTE `filteredValue: [searchedText]` set on one column will override other columns filter defined with `filters`.
            // So I need to add logic for each column using `filters`.
            // WIP
            filteredValue: [searchedText],
            onFilter: onFilterAll,
            // onFilter: (value: string | number | boolean, record: IOrder) =>
            //     record.offer_id
            //         .toString()
            //         .toLowerCase()
            //         .includes((searchedText as string).toLowerCase()),
        },
        {
            title: 'Plan',
            key: 'plan_id',
            dataIndex: 'plan_id',
            sorter: (a, b) => a.plan_id.localeCompare(b.plan_id),
        },
        {
            title: 'Purchased',
            key: 'created_at',
            dataIndex: 'created_at',
            render: (value) => prettyDate(new Date(value)),
            sorter: (a, b) => new Date(a?.created_at).getTime() - new Date(b?.created_at).getTime(),
        },

        {
            title: 'Updated',
            key: 'updated_at',
            dataIndex: 'updated_at',
            render: (value) => prettyDate(new Date(value)),
            sorter: (a, b) => new Date(a?.updated_at).getTime() - new Date(b?.updated_at).getTime(),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Subscription Status',
            key: 'status',
            dataIndex: 'status',
            render: (value) => <OrderStatus status={value} />,
            sorter: (a, b) => a.status?.localeCompare(b.status),
            filterMultiple: true,
            // filters: [
            //     { text: 'Subscribed', value: 'subscribed' },
            //     { text: 'Unsubscribed', value: 'unsubscribed' },
            //     { text: 'Requested', value: 'requested' },
            // ],
            // onFilter: (value, record) => record.status === value,
        },
        {
            title: 'Deployment Status',
            key: 'deployment_status',
            dataIndex: 'deployment_status',
            render: (value) => {
                return <DeploymentStatus status={value} />
            },
            sorter: (a, b) => a.deployment_status?.localeCompare(b?.deployment_status),
            // filters: [
            //     { text: 'Running', value: 'Running' },
            //     { text: 'Failed', value: 'Failed' },
            //     { text: 'Deploy Not Found', value: 'deploy_not_found' },
            // ],
            // onFilter: (value, record) => record.deployment_status === value,
        },
        {
            title: 'Client',
            key: 'client_name',
            dataIndex: 'client_name',
            render: (value, record) => (
                <div className="grid items-center grid-cols-1 md:grid-cols-[max-content_minmax(max-content,1fr)] gap-x-4 gap-y-2">
                    <div>
                        <Avatar size="large" style={{ background: token.colorBorder }} />
                    </div>
                    <div>
                        <Text className="block font-semibold">{record?.client_name}</Text>
                        <Text className="block" type="secondary">
                            {record?.client_email}
                        </Text>
                    </div>
                </div>
            ),
            sorter: (a, b) => a?.client_name.localeCompare(b?.client_name),
        },
    ]

    const rowClickHandler = (record: IOrder) => {
        navigate(`${record.id}`)
    }

    // JSX
    return (
        <>
            {/* table */}
            <Table
                columns={columns}
                dataSource={ordersQuery.data}
                pagination={{ size: 'default' }}
                loading={ordersQuery.isLoading}
                rowClassName="cursor-pointer"
                onRow={(record: IOrder) => {
                    return {
                        onClick: () => {
                            rowClickHandler(record)
                        },
                    }
                }}
            />

            {/* Error */}
            {ordersQuery.isError && (
                <div>
                    <div className="inline-block p-2 overflow-scroll text-red-500 rounded-md bg-red-50">
                        <div>
                            <span className="font-semibold">
                                Error {(ordersQuery.error as FetchBaseQueryError).status}
                            </span>{' '}
                            Cannot fetch orders.
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ListOrders
