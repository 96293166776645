import React from 'react'
import { Modal } from 'antd'

type Props = {
    open: boolean
    onOk: (event: React.MouseEvent<HTMLButtonElement>) => void
    onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const ModalConfirmExit = ({ open, onOk, onCancel }: Props) => {
    return (
        <Modal open={open} onOk={onOk} onCancel={onCancel}>
            <p>Quit editing?</p>
        </Modal>
    )
}
