import { Link, useRouteError } from 'react-router-dom'

const KubearkError = () => {
    const error: any = useRouteError()

    return (
        <div>
            <div className="inline-block p-2 overflow-scroll text-red-500 rounded-md bg-red-50">
                <div>
                    <span className="font-semibold">Error</span>
                    {/* TODO Keep only on dev? */}
                    <div className="block mb-4 overflow-scroll">{error.message}</div>
                    Go back to{' '}
                    <Link
                        to="/kubeark"
                        className="font-semibold text-red-500 underline active:text-red-400 hover:text-red-600"
                    >
                        Kubeark
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default KubearkError
