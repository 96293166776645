import { IOffer } from '../core-types'
import { FormOfferValues } from '../features/groupOffers/hooks/useFormOffer'

/**
 * Get an offer from an array of offers.
 *
 * @param offerId Offer Id
 * @param offers Array of offers
 */
export function getOfferFromOffers(id: number, offers: IOffer[]): IOffer | undefined {
    return offers.find((offer: IOffer) => {
        return offer.id === id
    })
}

/**
 * Convert the offer data stored in BE into in IOffer type used in FE.
 * If `parent_template_id` is not `null`, `template_id` represents a template version.
 * So for the IOffer type, set `template_version_id` to the template version and `template_id` to the parent template.
 *
 * If the offer data has a `null` `parent_template_id`, the offer does not have a version, so keep `template_id` same as the data.
 *
 * @param offerData
 * @returns offer: IOffer
 */
// WIP Keep in mind
export function convertOfferDataToIOffer(offerData: any): IOffer {
    // have to spread because the object returned from the data is non extensible
    let offer = { ...offerData }

    if (offerData.parent_template_id) {
        offer.template_version_id = offerData.template_id
        offer.template_id = offerData.parent_template_id
    } else {
        offer.template_version_id = null
    }

    // delete the property that was copied from the offer data
    delete offer.parent_template_id

    return offer as IOffer
}

/**
 * Convert offer form values to offer data for BE. See `convertOfferDataToIOffer` above for the reverse.
 *
 * @param offerValues
 * @returns newOfferData - Raw offer data used in BE.
 */
export function convertFormOfferValuesToOfferData(offerValues: FormOfferValues): any {
    const newOfferData: any = { ...offerValues }

    if (offerValues.template_version_id) {
        newOfferData.parent_template_id = offerValues.template_id
        newOfferData.template_id = offerValues.template_version_id
    } else {
        newOfferData.parent_template_id = null
        newOfferData.template_id = offerValues.template_id
    }

    // delete the property used in form offer values
    delete newOfferData.template_version_id

    return newOfferData
}
