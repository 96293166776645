import { Layout, theme } from 'antd'

import './mainContent.css'

const { Content } = Layout

const { useToken } = theme

// TODO Not sure I want to use children here. Maybe just kepp the routes inside.
type Props = {
    children: React.ReactNode
}

const MainContent: React.FC<Props> = ({ children }: Props) => {
    const { token } = useToken()

    return (
        <Content className="main-content">
            <div className="inner-container" style={{ backgroundColor: token.colorBgContainer }}>
                {children}
            </div>
        </Content>
    )
}

export default MainContent
