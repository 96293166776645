import React from 'react'
import { Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { getKubearkInstanceFromInstances } from '../../utilities/kubeark'
import { KubearkDetails } from './kubearkDetails/kubearkDetails'
import { Link, useParams } from 'react-router-dom'
import { useGetKubearkInstancesQuery } from '../api/apiSlice'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import '../../theme/components/view.css'

const { Title, Text } = Typography

interface IProps {}

export const ViewKubearkDetails: React.FC<IProps> = () => {
    // HOOKS

    const { id } = useParams()

    // get all the Kubeark instances (already cached most of the time)
    const kinstancesQuery = useGetKubearkInstancesQuery({})

    // JSX

    return (
        <div className="w-full view-body">
            {/* View header */}
            <header className="view-header">
                <div className="left-side">
                    <div className="title-container">
                        {/* Link back */}
                        <Link to={'/kubeark'}>
                            <div className="nav-icon">
                                <div className="icon-container">
                                    <ArrowLeftOutlined />
                                </div>
                            </div>
                        </Link>
                        <Title level={2} className="!my-0">
                            Kubeark
                        </Title>
                    </div>
                    <Text type="secondary">Kubeark instance details.</Text>
                </div>
                <div className="right-side">
                    {/* <Link to={`/kubeark/edit/${id}`}>
                        <Button type="primary">Edit Instance</Button>
                    </Link> */}
                </div>
            </header>

            {/* TODO Move the loading animation inside the Overview tab */}

            {kinstancesQuery.isLoading && (
                <div className="mt-2">{/* <Spin indicator={<LoadingOutlined className="text-sm" spin />} /> */}</div>
            )}

            {kinstancesQuery.isSuccess && (
                <div>
                    {/* Get the kubeark instance details, usually from the local cache (useGetKubearkInstancesQuery has already run).
                    Also make sure the kubeark instance still exists, in the rare possibility where it is not available in the cache anymore. */}
                    {getKubearkInstanceFromInstances(Number(id), kinstancesQuery.data)?.id ? (
                        <KubearkDetails
                            kubearkInstance={getKubearkInstanceFromInstances(Number(id), kinstancesQuery.data)!}
                        />
                    ) : (
                        <p className="text-red-400">
                            <span className="font-semibold">Error:</span> {`Kubeark instance not found.`}
                        </p>
                    )}
                </div>
            )}

            {kinstancesQuery.isError && (
                <p className="text-red-400">
                    <span className="font-semibold">Error {(kinstancesQuery.error as FetchBaseQueryError).status}</span>{' '}
                    Cannot get Kubeark instance.
                </p>
            )}
        </div>
    )
}
