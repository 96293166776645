import { Outlet } from 'react-router-dom'
import './rootLayout.css'
import { theme } from 'antd'

const { useToken } = theme

// I need this only to set the whole background color based on the theme.
// It's basically just another Outlet, you arrive here from the RootLayout Outlet
const RootContent = () => {
    const { token } = useToken()

    return (
        <div className="w-screen h-screen" style={{ backgroundColor: token.colorBgContainer }}>
            <Outlet />
        </div>
    )
}

export default RootContent
