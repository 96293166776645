import { useNavigate } from 'react-router-dom'

import { Table } from 'antd'

import { IKInstance } from '../../../core-types'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { ColumnType } from 'antd/es/table'

const onFilterAll = (value: string | number | boolean, record: IKInstance) =>
    record.name
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase())

type Props = {
    kinstancesQuery: any
    searchedText: string
}

const ListKubearks: React.FC<Props> = ({ kinstancesQuery, searchedText }: Props) => {
    // HOOKS

    const navigate = useNavigate()

    // table columns
    const columns: ColumnType<IKInstance>[] = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (value) => <span className="">{value}</span>,
            filteredValue: [searchedText],
            onFilter: onFilterAll,
        },
    ]

    const rowClickHandler = (record: IKInstance) => {
        navigate(`${record.id}`)
    }

    // JSX

    return (
        <>
            <Table
                columns={columns}
                dataSource={kinstancesQuery.data}
                pagination={{ size: 'default' }}
                loading={kinstancesQuery.isLoading}
                rowClassName="cursor-pointer "
                rowKey="run_id"
                onRow={(record: IKInstance) => {
                    return {
                        onClick: () => {
                            rowClickHandler(record)
                        },
                    }
                }}
            />

            {/* error */}
            {kinstancesQuery.isError && (
                <div>
                    <div className="inline-block p-2 overflow-scroll text-red-500 rounded-md bg-red-50">
                        <div>
                            <span className="font-semibold">
                                Error {(kinstancesQuery.error as FetchBaseQueryError).status}
                            </span>{' '}
                            Cannot fetch data.
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ListKubearks
