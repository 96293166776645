import useAuthDefaultNavigate from '../groupAuth/hooks/useAuthDefaultNavigate'
import useAuthPersistentCredentials from '../groupAuth/hooks/useAuthPersistentCredentials'
import useAuthSearchParams from '../groupAuth/hooks/useAuthSearchParams'

// NOTE AUTH
// This is a page displayed at the index route `/`.
// This is where I get redirected from IDP with the `access_token` and `refresh_token` query params.
export const PageHome: React.FC = () => {
    useAuthSearchParams()
    useAuthPersistentCredentials()
    useAuthDefaultNavigate()

    return <></>
}
