import { useEffect, useState } from 'react'
// rtkq
import {
    useGetKubearkInstanceClustersQuery,
    useGetKubearkInstancesQuery,
    useGetKubearkInstanceTemplatesMutation,
    useGetKubearkInstanceTemplateVersionsMutation,
} from '../../api/apiSlice'

// TODO rename this hook
const useOfferQueries = (kInstanceId: number | undefined, kTemplateId: number | undefined) => {
    // skip token for templates and clusters queries
    const [skipTemplatesQuery, setSkipTemplatesQuery] = useState(true)
    // skip token for template versions query

    // K instances query
    const queryKInstances = useGetKubearkInstancesQuery({})

    const [getKInstanceTemplates, mutationKTemplates] = useGetKubearkInstanceTemplatesMutation()

    const [getKInstanceTemplateVersions, mutationKTemplateVersions] = useGetKubearkInstanceTemplateVersionsMutation()

    // K clusters query
    const queryKClusters = useGetKubearkInstanceClustersQuery(kInstanceId!, {
        skip: skipTemplatesQuery,
    })

    // when I have a kInstanceId, load the templates and clusters for that instance
    useEffect(() => {
        if (kInstanceId) {
            // get the templates (It's a POST actually, as I have to send a body)
            setSkipTemplatesQuery(false)
            getKInstanceTemplates({ id: kInstanceId, perPage: 200 })
        }
    }, [getKInstanceTemplates, kInstanceId])

    // when I have a parentTemplateId, load the template versions
    useEffect(() => {
        if (kTemplateId) {
            // this is actually a POST
            getKInstanceTemplateVersions({
                id: kInstanceId,
                perPage: 200,
                parentTemplateId: kTemplateId,
            })
        }
    }, [getKInstanceTemplateVersions, getKInstanceTemplates, kInstanceId, kTemplateId])

    return {
        queryKInstances,
        mutationKTemplates,
        mutationKTemplateVersions,
        queryKClusters,
    }
}

export default useOfferQueries
